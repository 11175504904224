import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { UnauthorizedView, NotFoundView, AccountSettingsView, DiscoverView, GenerateArtView, IndexView } from '../views';
import ProjectView from '../views/ProjectView';
import ProjectDetailView from '../views/ProjectDetailView';
import SearchView from '../views/SearchView';
import AddViewPlaybook from '../views/AddViewPlaybook';

const ContentWrapper = () => {
  return (
    <main className="content-section">
        <div className="content_bg h_full">
            
              <Routes>
                  <Route path="/" element={<IndexView />} />
                  <Route path="/search/:query" element={<SearchView />} />
                  <Route path="/account-settings" element={<AccountSettingsView />} />
                  <Route path="/discover" element={<DiscoverView />} />
                  <Route path="/project" element={<ProjectView />} />
                  <Route path="/project/:id" element={<ProjectDetailView />} />
                  <Route path="/playbook" element={<AddViewPlaybook />} />
                  <Route path="/playbook/:id" element={<AddViewPlaybook />} />
                  <Route path="/generate-art" element={<GenerateArtView />} />
                  <Route path="/401" element={<UnauthorizedView />} />
                  <Route path="/404" element={<NotFoundView />} />
                  <Route element={<NotFoundView />} />
              </Routes>
        </div>
    </main>
  );
};

export default ContentWrapper;