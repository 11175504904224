import React, { useState } from 'react';
import './ContentCarousel.css';

const ContentCarousel = ({ contents, onSelect }) => {
  const [selectedContent, setSelectedContent] = useState(null);

  const handleSelect = (content) => {
    setSelectedContent(content);
    onSelect(content);
  };

  return (
    <div className="content-carousel">
      <div className="carousel">
        {contents.map((content, index) => (
          <div
            key={index}
          >
            <embed src={content.url} width="200" controls />
            <p>{content.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentCarousel;