import React, { useRef, useState, useEffect } from 'react';
import './VideoPlayer.css';

const parseTimestamp = (timestamp) => {
  const [minutes, seconds] = timestamp.split(':').map(Number);
  return minutes * 60 + seconds;
};

const VideoPlayer = ({ src, topics, hideProgressBar, showControls, pauseVideo }) => {
  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    const handleLoadedMetadata = () => setVideoDuration(video.duration);
    const handleTimeUpdate = () => setCurrentTime(video.currentTime);

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    video.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    video.pause()
  }, [pauseVideo])

  const handleProgressBarClick = (e) => {
    const progressBar = progressBarRef.current;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = (offsetX / rect.width) * videoDuration;
    videoRef.current.currentTime = newTime;
  };

  const handleChapterClick = (startTime) => {
    videoRef.current.currentTime = startTime;
  };

  return (
    <div className="video-player">
      <video ref={videoRef} className="modal-media" controls={showControls ?? true}>
        <source src={src} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      {!hideProgressBar &&<div className="progress-bar-container" onClick={handleProgressBarClick} ref={progressBarRef}>
        <div className="progress-bar" style={{ width: `${(currentTime / videoDuration) * 100}%` }}></div>
        <div className="chapters">
          {topics && topics.map((topic, index) => (
            <div
              key={index}
              className="chapter-marker"
              style={{ left: `${(parseTimestamp(topic.Timestamp.split('-')[0]) / videoDuration) * 100}%` }}
              onClick={(e) => { e.stopPropagation(); handleChapterClick(parseTimestamp(topic.Timestamp.split('-')[0])); }}
              title={topic.Topic}
            >
              <div className="chapter-title">{topic.Topic}</div>
            </div>
          ))}
        </div>
      </div>}
    </div>
  );
};

export default VideoPlayer;