export const airMaxPlusMetadata = {
    "Actor Identification": "Actor: Person 1",
    "Description": "Person 1 wearing Air Max Plus",
    "Actor Personalities": "Confident, Relaxed",
    "Emotion Detection": "Neutral",
    "Product Identification": "Air Max Plus sneakers",
    "Video Description Generation": "Person 1 poses casually against a wall wearing a green jacket, white pants, and black Air Max Plus sneakers",
    "Content Tagging": "Casual, Fashion, Relaxed"
};

export const airMax270Metadata = {
    "Actor Identification": "Actor: Person 2",
    "Description": "Person 2 wearing Air Max 270",
    "Actor Personalities": "Stylish, Thoughtful",
    "Emotion Detection": "Contemplative",
    "Product Identification": "Air Max 270 sneakers",
    "Video Description Generation": "Person 2 stands with hands in pockets, wearing a blue and white tracksuit and Air Max 270 sneakers",
    "Content Tagging": "Fashion, Contemplative, Stylish"
};

export const airMaxPulseMetadata = {
    "Actor Identification": "Actor: Person 3",
    "Description": "Person 3 wearing Air Max Pulse",
    "Actor Personalities": "Serious, Determined",
    "Emotion Detection": "Intense",
    "Product Identification": "Air Max Pulse sneakers",
    "Video Description Generation": "Person 3 looks serious while posing, wearing a grey outfit with Air Max Pulse sneakers",
    "Content Tagging": "Fashion, Serious, Intense"
};

export const contents = [
    { id: 1, title: 'Air Max Plus', type: 'video', thumbnail: 'images/nike-air-max-plus.png', url: 'images/nike-air-max-plus-video.mp4', description: 'Air Max Plus', metadata: airMaxPlusMetadata },
    { id: 2, title: 'Air Max 270', type: 'video', thumbnail: 'images/nike-air-max-270.png', url: 'images/nike-air-max-270.mov', description: 'Air Max 270', metadata: airMax270Metadata },
    { id: 3, title: 'Air Max Pulse', type: 'video', thumbnail: 'images/nike-air-max-pulse.png', url: 'images/nike-air-max-pulse-video.mp4', description: 'Air Max Pulse', metadata: airMaxPulseMetadata },
];