import React from 'react';
import { SignIn } from '@clerk/clerk-react';
import "./index.css";

function SignInPage() {
  return (
    <div>
      <SignIn path="/sign-in" routing="path" signUpUrl="/sign-up" />
    </div>
  );
}

export default SignInPage;
