/* eslint-disable */

import React, { useState } from 'react';
import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import VideoPlayer from './VideoPlayer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import PublicIcon from '@mui/icons-material/Public';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function FacebookPostPreview({ content, pauseVideo }) {
    return (
        <div>
        <div style={{ height: "50px", textAlign: "center"}}>
            <img src='../images/Facebook-Logo.png' alt='Facebook' style={{ height: "50px", transform: 'scale(1.4)', marginTop: '-8px'}} />
        </div>
        <Card style={{ boxShadow: 'none'}}>
            <CardHeader 
                style={{paddingBottom: '0'}}
                title={
                    <div style={{ display: 'flex', width: '100%'}}>
                        <span style={{ width: '32px', height: '32px', borderRadius: '50%', background:'#9c27b0', display: 'inline-block', textAlign:'center',  color:'#fff', marginRight: '10px', lineHeight: '32px', fontSize: '20px'}}>
                            <img src='../images/NikeLogo.jpg' alt='logo' style={{  borderRadius: '50%'}}/>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'column'}}>
                            <span style={{ fontSize: '16px', fontWeight: 'bold'}}>nike</span>
                            <span style={{ fontSize: '12px', color: '#90949c'}}>Sponsored <PublicIcon style={{ color: '#90949c', fontSize: '12px'}}/></span>
                        </span>
                        <span style={{ marginLeft: 'auto'}}><MoreHorizIcon style={{ fontSize: '18px'}}/></span>
                    </div>
                }
            />
            <CardContent style={{ paddingRight: '0', paddingLeft: '0' }}>
                <div className='content-builder-img' style={{ marginBottom: "0px" }}>
                    <div style={{ padding: '0px 15px 15px'}}><Typography style={{ fontSize: '14px'}}>{content.content}</Typography></div>
                    <VideoPlayer src={content.media_url} topics={[]} hideProgressBar={true} pauseVideo={pauseVideo} />
                    <div style={{ padding: '15px 15px 0px'}}>
                        <ul style={{ listStyle: 'none', display: 'flex', justifyContent: "space-between", paddingLeft: '0', alignItems: 'center', margin: 0}}>
                            <li style={{ display: 'flex', alignItems: 'center', textAlign: 'left', color: '#616770'}}><ThumbUpOutlinedIcon  style={{ fontSize: '22px'}} /><span style={{ marginLeft: '5px', fontWeight: 'bold'}}>Like</span></li>
                            <li style={{  display: 'flex', alignItems: 'center', textAlign: 'left', marginLeft: '15px',  color: '#616770'}}><ChatBubbleOutlineOutlinedIcon  style={{ fontSize: '20px'}} /><span  style={{ marginLeft: '5px', fontWeight: 'bold'}}>Comment</span></li>
                            <li style={{  display: 'flex', alignItems: 'center', color: '#616770'}}><ReplyIcon  style={{ fontSize: '22px',   transform: 'scaleX(-1)'}} /><span  style={{ marginLeft: '5px', fontWeight: 'bold'}}>Share</span></li>
                        </ul>
                    </div>
                </div>
            </CardContent>
        </Card>
        </div>
    )
}

function YoutubePreview({ content, pauseVideo }) {
    return (
        <div style={{ flex: "1" }}>
            <div>
                <div style={{ height: "50px", textAlign: "center"}}>
                    <img src='../images/youtube_logo.png' alt='Facebook' style={{ height: "30px", transformOrigin: "center center"}} />
                </div>
                <Card style={{ boxShadow: 'none'}}>
                    <CardContent style={{ padding: "0px" }}>
                        <div className='content-builder-img' style={{ marginBottom: "0px" }}>
                            <VideoPlayer src={content.media_url} topics={[]} hideProgressBar={true} pauseVideo={pauseVideo} />
                        </div>
                    </CardContent>
                    <CardHeader
                        style={{ padding: "16px 16px 16px 16px"}}
                        title={
                            
                            <div style={{ display: 'flex', width: '100%', gap: "10px"}}>
                                <span style={{ width: '36px', height: '36px', borderRadius: '50%', display: 'inline-block', textAlign:'center',  color:'#fff', lineHeight: '32px', fontSize: '20px'}}>
                                    <img src='../images/NikeLogo.jpg' alt='logo' style={{  borderRadius: '50%'}}/>
                                </span>
                                <span style={{ display: 'flex', flexDirection: 'column'}}>
                                    <span style={{ fontSize: '16px', fontWeight: '600'}}>nike</span>
                                    <span style={{ fontSize: '12px'}}>{content.content}</span>
                                    <span style={{ fontSize: '12px', color: "rgb(96, 96, 96)", display: "flex", alignItems: "center"}}>Nike <CheckCircleIcon fontSize='12px' style={{ marginLeft: "5px"}} /></span>
                                    <span style={{ fontSize: '12px', color: "rgb(96, 96, 96)"}}>
                                        27M Views <span className='yt-c-dot'></span> 6 days ago
                                    </span>
                                </span>
                                <span style={{ marginLeft: 'auto'}}><MoreVertIcon style={{ fontSize: '18px'}}/></span>
                            </div>
                        }
                    />
                </Card>
            </div>
        </div>
    )
}

const EntryVideoPostPreview = (props) => {
    const [pauseVideo, setPauseVideo] = useState(undefined)
    const pauseHiddenVideo = (now, prev) => {
        setPauseVideo(prev)
    }

    return (
        <Carousel animation="slide" autoPlay={false} indicators={false} navButtonsAlwaysVisible={true} height={500}   
            NextIcon={<ArrowForwardIosIcon/>}
            PrevIcon={<ArrowBackIosNewIcon/>}
            NavButton={({onClick, next, prev}) => {
                return (
                    <Button onClick={onClick} style={{ color: '#000', background: 'transparent', lineHeight: '15px'}}>
                        {next && <div><ArrowForwardIosIcon /></div>}
                        {prev && <div><ArrowBackIosNewIcon /></div>}
                    </Button>
                )
            }}
            onChange={(now, prev) => pauseHiddenVideo(now, prev)}
        >
            <YoutubePreview content={props.content} pauseVideo={pauseVideo === 0 ?? true} />
            <FacebookPostPreview content={props.content} pauseVideo={pauseVideo === 1 ?? true} />
        </Carousel>
    )
}

export default EntryVideoPostPreview;

/* eslint-enable */