import React from 'react';

const Form = ({ children, onSubmit, className, ...props }) => {
  return (
    <form onSubmit={onSubmit} className={`af-class-form_form ${className}`} {...props}>
      {children}
    </form>
  );
};

export default Form;