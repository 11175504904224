/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from '../components/Navigation';
import GlobalStyles from '../components/GlobalStyles';
import TextField from '../components/TextField';
import Form from '../components/Form';
import Navbar from '../components/Navbar';
import ChatForm from "../components/ChatForm";
import useAxiosInstance from '../axiosInstance';
import { Select, Option, CheckboxContainer, CheckboxLabel, Checkbox } from '../components/StyledComponents';
import { Button, Chip, Divider, Grid } from '@mui/material';

const scripts = [
  { src: "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6671e61719d3443cc0ddd63c", async: false },
  { src: "/js/webflow.js", async: false },
];

const loadScript = (src, async = false) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = async;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

const GenerateAdView = (props) => {
  const navigate = useNavigate();
  const [script, setScript] = useState('');
  const [avatar, setAvatar] = useState('');
  const [theme, setTheme] = useState('');
  const [newAd, setNewAd] = useState({ title: '', description: '' });
  const [videoRatios, setVideoRatios] = useState({
    '16:9': false,
    '4:3': false,
    '1:1': false,
  });
  const themes = ['Theme 1', 'Theme 2', 'Theme 3'];
  const [adType, setAdType] = useState('');
  const [adOption, setAdOption] = useState('');
  const avatarAds = ['Creator 1', 'Creator 2', 'Creator 3'];
  const staticPostAds = ['Static Post 1', 'Static Post 2', 'Static Post 3'];
  const inVideoAds = ['In-Video Ad 1', 'In-Video Ad 2', 'In-Video Ad 3'];
  const [showChatForm, setShowChatForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoId, setVideoId] = useState('');
  const [messages, setMessages] = useState([]);
  const [copyTarget, setCopyTarget] = useState(null);

  let adOptions = [];
  if (adType === 'Creator Ad') {
    adOptions = avatarAds;
  } else if (adType === 'Static Post Ad') {
    adOptions = staticPostAds;
  } else if (adType === 'In-Video Ad') {
    adOptions = inVideoAds;
  }

  useEffect(() => {
    const loadScripts = async () => {
      try {
        for (const script of scripts) {
          await loadScript(script.src, script.async);
        }
        console.log('All scripts loaded');
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };

    loadScripts();
  }, []);

  const handleGenerateAd = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/generate_ad`, { ad_slogan: script }, { withCredentials: true });
      setVideoId(response.data.video_id); // Set the video ID
      pollWebhook(response.data.video_id); // Start polling for the video URL
    } catch (error) {
      console.error('Error generating ad:', error);
      setLoading(false); // Stop loading if there's an error
    }
  };

  const pollWebhook = async (video_id) => {
    try {
      const response =await axiosInstance.get(`/video_status/${video_id}`, { withCredentials: true });
      if (response.data.video_url) {
        setVideoUrl(response.data.video_url);
        setLoading(false);
      } else {
        setTimeout(() => pollWebhook(video_id), 5000); // Poll every 5 seconds
      }
    } catch (error) {
      console.error('Error polling webhook:', error);
      setLoading(false); // Stop loading if there's an error
    }
  };

  const handleRatioChange = (e) => {
    const { name, checked } = e.target;
    setVideoRatios((prevRatios) => ({
      ...prevRatios,
      [name]: checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // navigate(-1);
  };

  const handleCopy = (content) => {
    setCopyTarget(content);
  };

  const applyCopy = (target) => {
    if (copyTarget) {
      if (target === 'title') {
        setNewAd({ ...newAd, title: copyTarget });
      } else if (target === 'description') {
        setNewAd({ ...newAd, description: copyTarget });
      } else if (target === 'script') {
        setScript(copyTarget);
      }
      setCopyTarget(null);
    }
  };

  const generateMockTitle = () => setNewAd({ ...newAd, title: 'Nike Air Max' });
  const generateMockDescription = () => setNewAd({ ...newAd, description: "Feel the ultimate comfort and unmatched style with Nike's Air Max shoes. Crafted for champions, pushing boundaries with bold colorways and revolutionary Air technology. Discover a perfect fusion of design and functionality, providing an unparalleled cushioning experience. Step out with confidence or race to new finish lines. Don't just conquer the ground - with Nike Air Max, make every step a statement." });
  const generateMockScript = () => setScript("Feel the ultimate comfort and unmatched style with Nike's Air Max shoes. Crafted for champions, pushing boundaries with bold colorways and revolutionary Air technology. Discover a perfect fusion of design and functionality, providing an unparalleled cushioning experience. Step out with confidence or race to new finish lines. Don't just conquer the ground - with Nike Air Max, make every step a statement.");

  return (
    <div className='content_box' style={{ maxWidth: '100%', padding: '0px 20px'}}>
      <GlobalStyles />
      <main>
        <div>
          <h2 style={{ margin: '15px 0px 0px !important'}}>{props.title}</h2>
          {/*<div className="thread-list" style={{ flexDirection: 'column'}}>
            <ChatForm
              messages={messages}
              setMessages={setMessages}
              onCopy={handleCopy}
            />
          </div>*/}
          <div className='create-form' style={{ background: 'transparent', padding: '0px !important', maxWidth: 'initial !important'}}>
              <div className="form-group" style={{ marginBottom: 10}}>
                 <label className="label">Title:</label>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={8}>
                        <TextField
                          placeholder="Title"
                          type="text"
                          value={newAd.title}
                          onChange={(e) => setNewAd({ ...newAd, title: e.target.value })}
                          required
                          className="form-control"
                          style={{ marginBottom: 0}}
                        />
                  
                  </Grid>
                  <Grid item xs={4}>
                    <Chip label='Generate Title' onClick={generateMockTitle} color='primary' variant='outlined' />
                  </Grid>
                </Grid>
              </div>
              <Divider style={{ margin: '10px 0' }}/>
              <div className="form-group" style={{ marginBottom: 10}}>
              <label className="label">Description:</label>
              <Grid container spacing={2} style={{ alignItems: 'center'}}>
                <Grid item xs={8}>
                    <textarea
                      placeholder="Description"
                      type="text"
                      value={newAd.description}
                      onChange={(e) => setNewAd({ ...newAd, description: e.target.value })}
                      required
                      className="form-control"
                      style={{ marginBottom: 0}}
                    />
                </Grid>
                <Grid item xs={4}>
                  <Chip label='Generate Description' onClick={generateMockDescription} color='primary' variant='outlined' />
                </Grid>
              </Grid>
              </div>
              <Divider style={{ margin: '10px 0' }}/>
              <div className="form-group" style={{ marginBottom: 10}}>
                <Grid container spacing={2} style={{ alignItems: 'center'}}>
                  <Grid item xs={8}>
                    <label className="label">Script:</label>
                    <textarea
                      value={script}
                      onChange={(e) => setScript(e.target.value)}
                      placeholder="Enter script here"
                      className="form-control"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Chip label='Generate Script' onClick={generateMockScript} color='primary' variant='outlined' />
                  </Grid>
                </Grid>
              </div>
              <Divider style={{ margin: '10px 0' }}/>
              <div className="form-group" style={{ marginBottom: 10}}>
                <Grid container spacing={2} style={{ alignItems: 'center'}}>
                  <Grid item xs={12}>
                    <label className="label">Choose Ad Type::</label>
                    <Select
                      value={adType}
                      onChange={(e) => {
                        setAdType(e.target.value);
                        setAdOption('');
                      }}
                      className="form-control"
                    >
                      <Option value="" disabled>Select an ad type</Option>
                      <Option value="Creator Ad">Creator Ad</Option>
                      <Option value="Static Post Ad">Static Post Ad</Option>
                      <Option value="In-Video Ad">In-Video Ad</Option>
                    </Select>
                    {adType && (
                    <div style={{ marginTop: '10px'}}>
                      <label className="label">Choose Option:</label>
                      <Select
                        value={adOption}
                        onChange={(e) => setAdOption(e.target.value)}
                        className="form-control"
                      >
                        <Option value="" disabled>Select an option</Option>
                        {adOptions.map((option, index) => (
                          <Option key={index} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  </Grid>
                </Grid>
              </div>
              <Divider style={{ margin: '10px 0' }}/>
              <div className="form-group" style={{ marginBottom: 10}}>
                <Grid container spacing={2} style={{ alignItems: 'center'}}>
                  <Grid item xs={12}>
                    <label className="label">Choose Theme:</label>
                      <Select
                        value={theme}
                        onChange={(e) => setTheme(e.target.value)}
                        className="form-control"
                      >
                      <Option value="" disabled>Select a theme</Option>
                      {themes.map((themeOption, index) => (
                        <Option key={index} value={themeOption}>
                          {themeOption}
                        </Option>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </div>
              <Divider style={{ margin: '10px 0' }}/>
            <div className="form-group" style={{ marginBottom: 0 }}>
            <label className="label">Select Video Ratios:</label>
              <div style={{ display: 'flex',}}>
                <CheckboxContainer style={{ margin : 0 }}>
                  <Checkbox
                    name="16:9"
                    checked={videoRatios['16:9']}
                    onChange={handleRatioChange}
                  />
                  <CheckboxLabel style={{ margin: '0 15px 0 5px'}} >16:9</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer style={{ margin : 0 }}>
                  <Checkbox
                    name="4:3"
                    checked={videoRatios['4:3']}
                    onChange={handleRatioChange}
                  />
                  <CheckboxLabel style={{ margin: '0 15px 0 5px'}}>4:3</CheckboxLabel>
                </CheckboxContainer>
                <CheckboxContainer style={{ margin : 0 }}>
                  <Checkbox
                    name="1:1"
                    checked={videoRatios['1:1']}
                    onChange={handleRatioChange}
                  />
                  <CheckboxLabel style={{margin: '0 15px 0 5px'}}>1:1</CheckboxLabel>
                </CheckboxContainer>
              </div>
              <Divider style={{ margin: '10px 0' }}/>
              <div style={{ textAlign: 'center', marginTop: '25px'}}>
                <Button onClick={handleGenerateAd} variant="contained" color="secondary" style={{ marginRight: '10px' }} size='large'>Build Video</Button>
                {/*<Button onClick={handleSubmit}  variant="contained" color="primary" style={{ marginRight: '10px' }}>Save</Button>
                <Button onClick={() => navigate('/discover')} variant="outlined" color="error" style={{ border: '1px solid'}}>Back</Button>*/}
              </div>
            </div>
            {loading && <div className="loading">Loading... Building a video...</div>}
            {videoUrl && (
              <div className="video-container">
                <h4>Generated Video:</h4>
                <video src={videoUrl} width="800" controls />
              </div>
            )}
        </div>
      </div>
      </main>
      {/* Modal and Backdrop */}
      {copyTarget && (
            <>
              <div className="modal-backdrop" onClick={() => setCopyTarget(null)}></div>
              <div className="copy-modal">
                <button className="close-btn" onClick={() => setCopyTarget(null)}>×</button>
                <h4>Copy to:</h4>
                <Button onClick={() => applyCopy('title')} className="btn">Title</Button>
                <Button onClick={() => applyCopy('description')} className="btn">Description</Button>
                <Button onClick={() => applyCopy('script')} className="btn">Script</Button>
              </div>
            </>
          )}
    </div>
  );
};

export default GenerateAdView;

/* eslint-enable */