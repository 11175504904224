import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useEffect, useState } from "react";
import NewPlayBookFormDialog from "./NewPlayBookFormDialog";
import { useNavigate } from "react-router-dom";
import HomeSearch from "./HomeSearch";
import { ContentGrid, SOURCE } from "../views/IndexView";
import HomeContentModal from "./HomeContentModal";
import AddIcon from "@mui/icons-material/Add";
import ContentCard from "../components/ContentCard";
import useAxiosInstance from "../axiosInstance";
import AjaxLoader from "./AjaxLoader";

function GridBox({ items, toggleSelect, selectedItems }) {
  return (
    <>
      {items.map((item, index) => (
        <Button
          disabled={index === 1 || index === 2}
          style={{
            fontSize: "20px",
            fontWeight: "300",
            textTransform: "capitalize",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          className={`select-card  ${
            selectedItems.includes(item) ? "selected" : ""
          }`}
          onClick={() => toggleSelect(item)}
          key={index}
        >
          {item}
        </Button>
      ))}
    </>
  );
}

function SearchContentGrid(props) {
  return (
    <div
      className="selectedProjectItem"
      style={{
        width: "100%",
        padding: "0px",
        background: "transparent",
        height: "255px",
        overflowY: "auto",
      }}
    >
      <div
        className="item-row"
        style={{ display: "flex", flexWrap: "wrap", margin: "10px 0px 0px" }}
      >
        {props.contents?.map((item, index) => {
          return (
            <div
              key={item.id}
              className="item"
              style={{ width: "100px", flex: "1 0 20%" }}
            >
              <ContentCard
                key={index}
                content={item}
                onClick={() => props.handleCardClick(item)}
                handleSelectedCollection={props.handleSelectedCollection}
                contentSrc={SOURCE.DIALOG}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function StepOne(props) {
  return (
    <>
      <div className="box-grid select-playbook-option">
        <GridBox
          items={["Content Calendar", "Ad Campaign", "Blank Playbook"]}
          toggleSelect={props.toggleSelect}
          selectedItems={props.selectedItems}
        />
      </div>
      <Stack
        spacing={2}
        direction={"row"}
        justifyContent={"center"}
        margin={"20px 0px 0px 0px"}
      >
        <Button
          style={{ width: "50%" }}
          variant="outlined"
          color="error"
          size="large"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          style={{ width: "50%" }}
          variant="contained"
          color="secondary"
          size="large"
          onClick={props.handleNext}
          disabled={props.selectedItems.length > 0 ? false : true}
        >
          Next
        </Button>
      </Stack>
    </>
  );
}

function StepTwo(props) {
  const [fields, setFields] = useState({ prompt: "", name: "" });
  const [showUploader, setShowUploader] = useState(false);
  const [checkedContents, setCheckedContents] = useState(
    props.checkedContents ?? []
  );
  const [contents, setContents] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [showValidateField, setShowValidateField] = useState(false);
  const [loading, setLoading] = useState(false);
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  const handleUploadSuccess = (newContent) => {
    setContents([{ ...newContent, isChecked: false }, ...contents]);
  };

  const fetchLoading = (loading) => {
    setLoading(loading);
  };

  const handleSearchResult = (data) => {
    const searchedData = [];
    data.forEach((item) => {
      const checkedItem = checkedContents.find((elem) => elem.id === item.id);
      if (checkedItem) {
        searchedData.push({ ...item, isChecked: true });
      } else {
        searchedData.push({ ...item, isChecked: false });
      }
    });
    setLoading(false);
    setContents(searchedData);
  };
  const handleClearSearch = (data) => {
    setContents(data);
  };
  const openNewProjectDialog = () => {
    //
  };
  const setShowCollections = () => {
    //
  };

  const handleCardClick = (item) => {
    setSelectedContent(item);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedContent(null);
  };

  const handleAddToCollection = (content) => {
    setShowCollections(false);
    const itemExist =
      checkedContents.length > 0 &&
      checkedContents.find((item) => item.id === content.id);
    if (!itemExist) {
      setCheckedContents([...checkedContents, content]);
    }
  };

  const handleRemoveCollection = (content) => {
    setCheckedContents(
      [...checkedContents].filter((item) => item.id !== content.id)
    );
  };

  const handleSelectedCollection = (content) => {
    if (content.isChecked === true) {
      setCheckedContents([...checkedContents, content]);
    } else {
      setCheckedContents(
        [...checkedContents].filter((item) => item.id !== content.id)
      );
    }
  };

  const fetchVideos = async () => {
    try {
      const response = await axiosInstance.get(`/get_content`);
      setContents(response.data.content);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const showSearchBar = () => {
    setShowUploader(true);
    setContents(checkedContents);
    //fetchVideos();
  };

  const validateForm = () => {
    const isValid = fields.prompt?.trim();
    setShowValidateField(!isValid);
    return !!isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      props.handleSubmitCreatePlaybook(fields, checkedContents);
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        {props.errorMessage && (
          <Alert
            severity="error"
            color="error"
            style={{ marginBottom: "10px" }}
          >
            {props.errorMessage}
          </Alert>
        )}
        <form className="form" onSubmit={props.handleSubmit}>
          <div className="form-group" style={{ margin: 0 }}>
            <label className={showValidateField ? "validate label" : "label"}>
              AI Prompt (Brief Description of what you want to create){" "}
              <span className="asteRisk">*</span>
            </label>
            <textarea
              type="text"
              value={fields.prompt}
              onChange={(e) => setFields({ ...fields, prompt: e.target.value })}
              required
              className={
                showValidateField ? "validate form-control" : "form-control"
              }
            />
          </div>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={fields.name}
              className="form-control"
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
              required
            />
          </div>
        </form>
        <Alert
          className="home-alert"
          severity="info"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <span className="text-underline">
            {checkedContents.length} Items Selected
          </span>
          <Button
            onClick={showSearchBar}
            variant="outlined"
            style={{ textTransform: "capitalize", marginLeft: "auto" }}
          >
            Add Content
          </Button>
        </Alert>
        <Stack
          spacing={2}
          direction={"row"}
          justifyContent={"center"}
          margin={"0 0px 20px 0px"}
        >
          <Button
            style={{ width: "50%" }}
            variant="outlined"
            color="error"
            size="large"
            onClick={props.handleBack}
          >
            Back
          </Button>
          <Button
            style={{ width: "50%" }}
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleSubmit}
          >
            Create Playbook
          </Button>
        </Stack>
      </div>
      {showUploader && (
        <div role="presentation" className="dialogue-content-uploader">
          <HomeSearch
            position={"static"}
            handleContentUpdate={handleUploadSuccess}
            handleSearchResult={handleSearchResult}
            handleClearSearch={handleClearSearch}
            selectedItems={checkedContents}
            openNewProjectDialog={openNewProjectDialog}
            setShowCollections={setShowCollections}
            fetchLoading={fetchLoading}
          />
          <Stack direction={"row"} style={{ padding: "10px" }}>
            <span style={{ fontWeight: "bold" }}>
              {checkedContents.length} Items Selected
            </span>
            <span
              style={{ cursor: "pointer", marginLeft: "auto" }}
              onClick={() => setShowUploader(false)}
            >
              <CloseRoundedIcon fontSize="small" />
            </span>
          </Stack>
          {contents && !loading ? (
            <SearchContentGrid
              contents={contents}
              handleCardClick={handleCardClick}
              handleAddToCollection={handleAddToCollection}
              handleRemoveCollection={handleRemoveCollection}
              handleSelectedCollection={handleSelectedCollection}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton
                variant="rectangular"
                style={{ flex: "1", marginRight: "15px" }}
                height={100}
              />
              <Skeleton
                variant="rectangular"
                style={{ flex: "1", marginRight: "15px" }}
                height={100}
              />
              <Skeleton
                variant="rectangular"
                style={{ flex: "1" }}
                height={100}
              />
            </div>
          )}
          <HomeContentModal
            content={selectedContent}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        </div>
      )}
    </div>
  );
}

function NewPlaybookDialog({
  contents,
  open,
  onClose,
  checkedContents,
  setShowCollections,
}) {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const navigate = useNavigate();
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  const toggleSelect = (item) => {
    setSelectedItems(item);
  };

  const handleNext = () => {
    setCurrentStep(2);
  };

  const handleSubmitCreatePlaybook = async (fields, content) => {
    setLoader(true);
    const knowledge = [];
    content.forEach((item) => {
      knowledge.push({ id: item.id, type: item.type });
    });
    const payload = {
      prompt: fields.prompt,
      name: fields.name,
      knowledge: knowledge ?? [],
    };

    try {
      const response = await axiosInstance.post(`/create_playbook`, payload, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.data && response.data.id) {
        setLoader(false);
        onClose();
        navigate(`/playbook/${response.data.id}?activeTab=1`);
      }
    } catch (err) {
      setLoader(false);
      setErrorMessage(
        "Something went wrong while creating new playbook. Please try again or contact us."
      );
    }
  };

    const handleBack = () => {
        setCurrentStep(1)
    }

   /*const handleSubmitCreatePlaybook = async(fields, content) => {
        setLoader(true);
        const knowledge = []
        content.forEach(item => {
            knowledge.push({ id: item.id, type: item.type })
        }); 
        const payload = {
            prompt: fields.prompt,
            name: fields.name,
            knowledge: knowledge ?? []
        }
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/create_playbook`, payload, { headers: { 'Content-Type': 'application/json' }})
            if ( response.data && response.data.id ) {
                setLoader(false);
                onClose();
                navigate(`/playbook/${response.data.id}?activeTab=1`);
            }
        }
        catch(err) {
            setLoader(false);
            setErrorMessage('Something went wrong while creating new playbook. Please try again or contact us.')
        }
    }*/

    //Mock Function
    const handleSubmitCreateMockPlaybook = async(fields, content) => {
        const mockId = 1;
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
            onClose();
            navigate(`/playbook/${mockId}?activeTab=1`);
        }, 3000);
    }

    return (
        <>
            <Dialog maxWidth='md' fullWidth open={open} onClose={onClose}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseRoundedIcon fontSize="large" color="#fff" />
                </IconButton>
                <DialogTitle fontSize="28px" fontWeight={"300"} textAlign={"center"}>
                    {currentStep === 1 ? 'Select Playbook' : 'New Playbook'}
                </DialogTitle>
                <DialogContent style={{ position: "relative" }}>
                    {currentStep === 1 && <StepOne
                        toggleSelect={toggleSelect}
                        selectedItems={selectedItems}
                        onClose={onClose}
                        handleNext={handleNext}
                    />}
                    {currentStep === 2 && <StepTwo
                        toggleSelect={toggleSelect}
                        checkedContents={checkedContents ?? []}
                        onClose={onClose}
                        handleBack={handleBack}
                        handleSubmitCreatePlaybook={handleSubmitCreateMockPlaybook}
                        setShowCollections={setShowCollections}
                        errorMessage={errorMessage}
                    />}
                </DialogContent> 
            </Dialog>
            <AjaxLoader open={loader}/>
        </>
    )
}

export default NewPlaybookDialog;
