import React from 'react';

const Button = ({ children, onClick, className, style, ...props }) => {
  return (
    <button onClick={onClick} className={`af-class-button ${className}`} style={style} {...props}>
      {children}
    </button>
  );
};

export default Button;