import React, { useState } from "react";
import useAxiosInstance from "../axiosInstance";
import { FaUser, FaRobot, FaCopy } from "react-icons/fa";
import Button from "./Button";
import TextField from "./TextField";
import { IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { UserButton } from "@clerk/clerk-react";

const ChatForm = ({ messages, setMessages, onCopy }) => {
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  const handleSend = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setLoading(true);
    const newMessages = [...messages, { role: "user", content: input }];
    setMessages(newMessages);

    try {
      const response = await axiosInstance.get(
        `/chat`,
        { input },
        { withCredentials: true }
      );
      setMessages([
        ...newMessages,
        { role: "assistant", content: response.data.response },
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
      setInput("");
    }
  };

  return (
    <div className="chat-form">
      <div className="messages" style={{ padding: "10px 30px" }}>
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {message.role === "user" ? (
              <span className="icon-badge">
                <UserButton className="icon user-icon" />
              </span>
            ) : (
              <span className="icon-badge bot">
                <FaRobot className="icon robot-icon" />
              </span>
            )}
            <div key={index} className={`message ${message.role}`}>
              <span className="message-content">{message.content}</span>
              {message.role === "assistant" && (
                <FaCopy
                  className="icon copy-icon"
                  onClick={() => onCopy(message.content)}
                />
              )}
            </div>
          </div>
        ))}
        {loading && (
          <div className="loading">ByterollAI is generating a response...</div>
        )}
      </div>
      <form onSubmit={handleSend} className="input-form">
        <TextField
          className="af-class-form_input-2 af-class-is-search-input w-input"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
          disabled={loading}
        />
        <IconButton type="submit" disabled={loading}>
          <SendIcon />
        </IconButton>
      </form>
    </div>
  );
};

export default ChatForm;
