import React, { useEffect, useState } from 'react';
import './ContentModal.css';
import MetadataTable from './MetadataTable';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import JsonToTable from './JsonToTable';
import VideoPlayer from './VideoPlayer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HomeContentModal = ({ content, isOpen, onClose, initialIndex = 0 }) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    useEffect(() => {
        setCurrentIndex(initialIndex);
    }, [initialIndex]);

    if (!isOpen || !content ) return null;

    const contentItem = content;

    const cleanJsonString = (jsonString) => {
        return jsonString.trim().replace(/^"|"$/g, '');
    };

    const parseMetadata = (metadata) => {
        const cleanedMetadata = cleanJsonString(metadata);
        return JSON.parse(cleanedMetadata);
    };

    const renderContent = () => {
        switch (contentItem.type) {
            case 'video':
                return <VideoPlayer src={contentItem.url} topics={contentItem.topics} />;
            case 'image':
                return <img src={contentItem.url} alt={contentItem.name} className="content-image" />;
            case 'pdf':
                return (
                    <div className="pdf-container">
                        <iframe title={contentItem.type} src={contentItem.url} type="application/pdf" width="100%" height="400px" />
                    </div>
                );
            case 'link':
                return <img src={contentItem.thumbnail_url} alt={contentItem.name} className="content-image" />;
            default:
                return <div>Unsupported content type</div>;
        }
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : content.link_contents.length - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < content.link_contents.length - 1 ? prevIndex + 1 : 0));
    };

    return (
        <div className="modal-overlay">
            <Dialog maxWidth='lg' open={isOpen} style={{ padding: '0px'}}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    > 
                    <CloseIcon />
                </IconButton>
                <DialogContent  style={{ display: 'flex', padding: '0px' }}>
                    <div className="modal-left" style={{ position: 'relative', display: 'flex', alignItems: 'center', maxHeight: '-webkit-fill-available'}}>
                        
                        {renderContent()}
                        
                    </div>
                    <div className="modal-right">
                        <h2 style={{ fontSize: '24px', lineHeight: '24px', margin: '50px 0px 0px', padding: "0px 0px 0px 0px" }}>{content.title}</h2>
                        {/* <p>{content.description}</p> */}
                        <JsonToTable data={parseMetadata(content.metadata)} />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default HomeContentModal;