import React from 'react';

const TextField = ({ label, name, placeholder, type = 'text', className, ...props }) => {
  return (
    <div className="af-class-form_field-wrapper">
      {label ? <label htmlFor={name} className="af-class-form_label">{label}</label> : ''}
      <input className={`af-class-form_input ${className}`} maxLength={256} name={name} placeholder={placeholder} type={type} id={name} {...props} />
    </div>
  );
};

export default TextField;