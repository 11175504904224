import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import useAxiosInstance from "../axiosInstance";
import FileUploadModal from "./FileUploadModal";
import AddLinkIcon from "@mui/icons-material/AddLink";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function HomeSearch({
  handleContentUpdate,
  handleSearchResult,
  handleClearSearch,
  position,
  fetchLoading,
}) {
  const [query, setQuery] = React.useState("");
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [contents, setContents] = useState("");
  const [modalType, setModalType] = useState("");
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  const handleFileUploadModalOpen = (type) => {
    setModalType(type);
    setIsFileUploadModalOpen(true);
  };

  const handleFileUploadModalClose = () => {
    setIsFileUploadModalOpen(false);
  };

  const handleSearch = async (e) => {
    setQuery(e.target.value);
    fetchData();
  };

  const fetchData = async () => {
    if (fetchLoading) {
      fetchLoading(true);
    }
    const response = await axiosInstance.post(`/search_semantic`, {
      prompt: query,
    });
    handleSearchResult(response.data);
  };

  const handleUploadSuccess = (
    name,
    type,
    url,
    thumbnailUrl,
    metadata,
    voiceover_tags,
    topics,
    link_contents
  ) => {
    if (type === "link") {
      const newContent = {
        id: contents.length + 1,
        name: name,
        type: type,
        thumbnail_url: thumbnailUrl,
        url: url,
        description: "Uploaded Video Description",
        metadata: metadata,
        voiceover_tags: voiceover_tags,
        topics: topics,
      };
      handleContentUpdate(newContent);
    } else {
      const newContent = {
        id: contents.length + 1,
        name: name,
        type: type,
        thumbnail_url: thumbnailUrl,
        url: url,
        description: "Uploaded Video Description",
        metadata: metadata,
        voiceover_tags: voiceover_tags,
        topics: topics,
        link_contents: link_contents,
      };
      handleContentUpdate(newContent);
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.value === "") handleClearSearch();
    setQuery(e.target.value);
  };

  return (
    <div
      style={{
        position: position ?? "fixed",
        bottom: "50px",
        width: "100%",
        maxWidth: "768px",
      }}
      className="home-search"
    >
      <div
        style={{
          background: "rgba(0, 0, 0, 0.05)",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Paper
          component="div"
          sx={{
            p: "2px 4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="searchHome"
        >
          <InputBase
            sx={{ ml: 1, mt: 1, mb: 1, flex: 1, width: "100%" }}
            placeholder="Search"
            autoFocus
            maxLength={256}
            name="query"
            type="search"
            id="search"
            value={query}
            onChange={handleSearchChange}
            required
            inputprops={{ "aria-label": "search" }}
          />
          <Stack direction={"row"} spacing={2} width={"100%"}>
            <span
              style={{
                display: "flex",
                fontSize: "12px",
                margin: "8px 0 0 10px",
                cursor: "pointer",
              }}
              onClick={() => handleFileUploadModalOpen("URL")}
            >
              <AddLinkIcon style={{ marginRight: "5px" }} fontSize="small" />{" "}
              URL
            </span>
            <span
              style={{
                display: "flex",
                fontSize: "12px",
                margin: "8px 0 0 15px",
                cursor: "pointer",
              }}
              onClick={() => handleFileUploadModalOpen("UPLOAD")}
            >
              <CloudUploadIcon
                style={{ marginRight: "5px" }}
                fontSize="small"
              />{" "}
              Content Upload
            </span>
            <IconButton
              style={{ marginLeft: "auto" }}
              type="button"
              aria-label="search"
              onClick={(e) => handleSearch(e)}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Paper>
        <FileUploadModal
          isOpen={isFileUploadModalOpen}
          onClose={handleFileUploadModalClose}
          onUploadSuccess={handleUploadSuccess}
          modalType={modalType}
        />
      </div>
    </div>
  );
}
