import React, { useEffect, useState } from "react";
import { Select } from "./StyledComponents";
import TextField from "./TextField";
import { Button, Stack } from "@mui/material";
import ContentCard from "./ContentCard";
import KnowledgeCard from "./KnowledgeCard";
import ContentCarousel from "./ContentCarousel";
import useAxiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";

function ConfigurationForm({ query, checkedContents }) {
  const navigate = useNavigate();
  const [fields, setFields] = useState({ title: "", summary: "" });
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  const handleGenerateConfig = async () => {
    try {
      const response = await axiosInstance.get(`/config_form`, {
        text_content: query,
      });
      if (response.status === 200) {
        setFields({
          title: response.data.title,
          summary: response.data.summary,
        });
      } else {
        console.error("Error generating configuration content");
      }
    } catch (error) {
      console.error("Error generating configuration content:", error);
    }
  };

  if (!fields.title && !fields.summary) {
    handleGenerateConfig();
  }

  const handleReset = () => {
    setFields({
      title: "",
      summary: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const projectData = {
      project_name: query,
      title: fields.title,
      summary: fields.summary,
      text_content: query,
    };

    try {
      const response = await axiosInstance.get(`/add_project`, projectData);
      if (response.status === 200) {
        navigate("/project/" + response.data.id);
      } else {
        console.error("Error creating project");
      }
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };

  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="label">Title</label>
          <TextField
            type="text"
            id="title"
            name="title"
            value={fields.title}
            onChange={(e) => setFields({ ...fields, title: e.target.value })}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label className="label">Summary</label>
          <textarea
            placeholder="Description"
            type="text"
            value={fields.summary}
            onChange={(e) => setFields({ ...fields, summary: e.target.value })}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label className="label">Knowledge</label>
          <ContentCarousel contents={checkedContents} />
        </div>
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="small"
            style={{
              width: "130px",
              fontSize: "14px",
              textTransform: "inherit",
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="small"
            style={{
              width: "130px",
              fontSize: "14px",
              textTransform: "inherit",
            }}
          >
            Create
          </Button>
        </Stack>
      </form>
    </div>
  );
}

export default ConfigurationForm;
