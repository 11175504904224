import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createScope, map, transformProxies } from './helpers';
import Navigation from '../components/Navigation';
import GlobalStyles from '../components/GlobalStyles';
import Button from '../components/Button';
import TextField from '../components/TextField';
import Form from '../components/Form';
import Navbar from '../components/Navbar';
import ChatForm from '../components/ChatForm';
import MarketingPlanForm from '../components/MarketingPlanForm';
import MarketingPlanModal from '../components/MarketingPlanModal';
import { Divider, Stack, Tab, Tabs } from '@mui/material';
import useAxiosInstance from '../axiosInstance';
import ConfigurationForm from '../components/ConfigurationForm';
import MarketingPlanBar from '../components/MarketingPlanBar';

const mockConversations = {
  "Nike Trends 2024": [
    { role: 'assistant', content: "Ask me anything regarding your project" },
    // { role: 'user', content: "Yes" }
  ],
  "Tik Tok Nike": [
    { role: 'user', content: "How does Nike use social media?" },
    { role: 'assistant', content: "Nike uses social media platforms like TikTok and Instagram for targeted marketing and engaging with younger audiences." }
  ],
  "Nike Air Max": [
    { role: 'user', content: "Tell me about Nike Air Max." },
    { role: 'assistant', content: "The Nike Air Max line is renowned for its innovative air cushioning technology and has been a staple in athletic and casual footwear since its debut." }
  ],
  "Create Nike Ad": [
    { role: 'user', content: "How can I create an effective Nike ad?" },
    { role: 'assistant', content: "An effective Nike ad should focus on storytelling, highlighting the brand's commitment to performance and innovation while engaging the audience emotionally." }
  ],
  "How is Nike successful": [
    { role: 'user', content: "What makes Nike successful?" },
    { role: 'assistant', content: "Nike's success is attributed to its strong brand identity, innovative products, effective marketing strategies, and commitment to athletes." }
  ]
};

const ProjectView = () => {
  const location = useLocation();
  const { query, checkedContents, project_id } = location.state || {};
  const { project } = location.state || {};
  const [selectedProject, setSelectedProject] = useState(project?.project_name || '');
  const [selectedThread, setSelectedThread] = useState('');
  const [messages, setMessages] = useState(mockConversations["Nike Trends 2024"] || []);
  const [formData, setFormData] = useState({
    campaignName: '',
    startDate: '',
    endDate: '',
    targetAudience: '',
    budget: '',
    objectives: '',
    strategies: '',
    tactics: '',
    metrics: '',
  });
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  const fetchProjectMessages = async (projectId) => {
    try {
      const response =await axiosInstance.get(`/get_project_messages/${projectId}`);
      setMessages(response.data.messages);
      setSelectedProject(response.data.project.project_name);
    } catch (error) {
      console.error('Error fetching project messages:', error);
    }
  };

  useEffect(() => {
    if (project_id) {
      fetchProjectMessages(project_id);
    }
  }, [project_id]);

  const handleSelectProject = (projectId) => {
    setSelectedProject(projectId)
    fetchProjectMessages(projectId);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content);
    alert('Copied to clipboard');
  };

  const handleCreateCampaign = () => {
    navigate('/generate-art-with-plan', { state: formData });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  return (
    <div className='project-view' style={{ width: "100%", padding : '0px 0 0 24px'}}>
     <GlobalStyles />
      <Stack direction="row" spacing={0} style={{ height: '100%' }} className='stackRow'>
        <div className="section-create-project sectionLeft" style={{ width: "50%", flex: "1", borderRadius: "8px 0 0 8px", marginLeft: '-24px', padding: '15px 30px 15px 30px', background: '#f8f8f8', height: '100%'}}>
            <h2 style={{ marginTop: 0, textAlign: "center" }}>New Project</h2>
            <Divider />
            <div>
                <Tabs value={currentTabIndex} onChange={handleTabChange} className='tabs' style={{ margin: "0 0px 30px 0"}}>
                    <Tab label='Planner' />
                </Tabs>
                {currentTabIndex === 0 && (<ConfigurationForm query={query} checkedContents={checkedContents} />)}
            </div>
        </div>
        <div style={{ padding: '0px 0px', background: "#fff", width: "50%"}} className='sectionRight'>
            <ChatForm
              messages={messages}
              setMessages={setMessages}
              onCopy={handleCopy}
            />
        </div>
      </Stack>
    </div>
  );
};

export default ProjectView;