import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import clerkConfig from "./clerkConfig";
import SignInPage from './SignIn';
import SignUpPage from './SignUp';


ReactDOM.createRoot(document.getElementById("root")).render(
  <ClerkProvider {...clerkConfig}>
    <Router>
      <Routes>
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  </ClerkProvider>
);

reportWebVitals();
