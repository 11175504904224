import React, { useEffect, useState } from "react";
import GlobalStyles from "../components/GlobalStyles";
import ContentCard from "../components/ContentCard";
import "../components/ContentCard.css";
import "../components/ContentModal.css";
import "../components/MetadataTable.css";
import useAxiosInstance from "../axiosInstance";
import HomeSearch from "../components/HomeSearch";
import HomeContentModal from "../components/HomeContentModal";
import { Alert, Button, Stack } from "@mui/material";
import NewPlaybookDialog from "../components/NewPlaybookDialog";
import SidebarDrawer from "../shared/SidebarDrawer";

export const SOURCE = {
  SIDEBAR: "SIDEBAR",
  CONTENT: "CONTENT",
  DIALOG: "DIALOG",
};

export function ContentGrid(props) {
  return (
    <div className="content-grid">
      {props.contents.length > 0 ? (
        props.contents.map((content, index) => (
          <ContentCard
            key={content.id}
            content={content}
            onClick={props.handleCardClick}
            handleAddToCollection={props.handleAddToCollection}
            handleRemoveCollection={props.handleRemoveCollection}
            handleSelectedCollection={props.handleSelectedCollection}
          />
        ))
      ) : (
        <div>No videos found</div>
      )}
    </div>
  );
}

const IndexView = () => {
  const [selectedContent, setSelectedContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contents, setContents] = useState("");
  const [checkedContents, setCheckedContents] = useState([]);
  const [showCollections, setShowCollections] = useState(false);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const axiosInstance = useAxiosInstance();
  const fetchVideos = async () => {
    try {
      const response = await axiosInstance.get(`/get_content`);
      const updatedContent =
        response.data.content &&
        response.data.content.map((item) => ({ ...item, isChecked: false }));
      setContents(updatedContent);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);


  const handleCardClick = (item) => {
    setSelectedContent(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedContent(null);
  };

  const handleUploadSuccess = (newContent) => {
    setContents([newContent, ...contents]);
  };

  const handleSearchResult = (data) => {
    setContents(data);
  };

  const handleClearSearch = () => {
    fetchVideos();
  };

  const handleAddToCollection = (content) => {
    const itemExist =
      checkedContents.length > 0 &&
      checkedContents.find((item) => item.id === content.id);
    if (!itemExist) {
      setCheckedContents([...checkedContents, content]);
    }
  };

  const handleRemoveCollection = (content) => {
    setCheckedContents(
      [...checkedContents].filter((item) => item.id !== content.id)
    );
  };

  const handleSelectedCollection = (content) => {
    if (content.isChecked === true) {
      setCheckedContents([...checkedContents, content]);
    } else {
      setCheckedContents(
        [...checkedContents].filter((item) => item.id !== content.id)
      );
    }
  };

  const openNewProjectDialog = () => {
    setShowProjectDialog(true);
  };

  const closeNewProjectDialog = () => {
    setShowProjectDialog(false);
  };

  return (
    <Stack
      style={{
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="cardGrid"
    >
      <GlobalStyles />
      <div className="content_box card_wrapper" id="first-row">
        <ContentGrid
          contents={contents}
          handleCardClick={handleCardClick}
          handleAddToCollection={handleAddToCollection}
          handleRemoveCollection={handleRemoveCollection}
          handleSelectedCollection={handleSelectedCollection}
        />
        <HomeSearch
          handleContentUpdate={handleUploadSuccess}
          handleSearchResult={handleSearchResult}
          handleClearSearch={handleClearSearch}
          selectedItems={checkedContents}
          openNewProjectDialog={openNewProjectDialog}
          setShowCollections={setShowCollections}
        />
        <HomeContentModal
          content={selectedContent}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      </div>

      <SidebarDrawer // Drawer to show selected content
        showCollections={showCollections}
        setShowCollections={setShowCollections}
        handleRemoveCollection={handleRemoveCollection}
        handleCardClick={handleCardClick}
        checkedContents={checkedContents}
      />

      {checkedContents && checkedContents.length > 0 && (
        <Alert
          className="home-alert"
          severity="info"
          style={{
            cursor: "pointer",
            alignItems: "center",
            marginBottom: "20px",
            borderRadius: "10px",
            position: "fixed",
            right: "10px",
            top: "20px",
            minWidth: "350px",
          }}
        >
          <span
            onClick={() => setShowCollections(true)}
            className="text-underline"
          >
            {checkedContents.length} Item Selected
          </span>
          <Button
            color="secondary"
            variant="contained"
            className="ml-auto"
            onClick={openNewProjectDialog}
          >
            New Playbook
          </Button>
        </Alert>
      )}

      {showProjectDialog && (
        <NewPlaybookDialog
          open={showProjectDialog}
          onClose={closeNewProjectDialog}
          checkedContents={checkedContents}
          setShowCollections={setShowCollections}
        />
      )}
    </Stack>
  );
};

export default IndexView;
