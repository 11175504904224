/* eslint-disable */

import React from 'react';
import { Link } from 'react-router-dom';
import { createScope, map, transformProxies } from './helpers';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6671e61719d3443cc0ddd63c").then(body => body.text()), isAsync: false },
  { loading: fetch("/js/webflow.js").then(body => body.text()), isAsync: false },
];

let Controller;

class NotFoundView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require('../controllers/NotFoundController');
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code === 'MODULE_NOT_FOUND') {
        Controller = NotFoundView;
        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = '6671e61719d3443cc0ddd676';
    htmlEl.dataset['wfSite'] = '6671e61719d3443cc0ddd63c';

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

        return next;
      });

      return active.isAsync ? next : loading;
    }));
  }

  render() {
    const proxies = NotFoundView.Controller !== NotFoundView ? transformProxies(this.props.children) : {};

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url('/css/normalize.css');
          @import url('/css/webflow.css');
          @import url('/css/devs-fantabulous-site-547be8.webflow.css');
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <div className="af-class-global-styles w-embed">
                <style dangerouslySetInnerHTML={{ __html: `
                  .af-view {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-smoothing: antialiased;
                    text-rendering: optimizeLegibility;
                  }
                  .af-view *[tabindex]:focus-visible,
                  .af-view input[type="file"]:focus-visible {
                    outline: 0.125rem solid #4d65ff;
                    outline-offset: 0.125rem;
                  }
                  .af-view .w-richtext > :not(div):first-child, 
                  .af-view .w-richtext > div:first-child > :first-child {
                    margin-top: 0 !important;
                  }
                  .af-view .w-richtext>:last-child,
                  .af-view .w-richtext ol li:last-child,
                  .af-view .w-richtext ul li:last-child {
                    margin-bottom: 0 !important;
                  }
                  .af-view .af-class-pointer-events-off {
                    pointer-events: none;
                  }
                  .af-view .af-class-pointer-events-on {
                    pointer-events: auto;
                  }
                  .af-view .af-class-div-square::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                  }
                  .af-view .af-class-container-medium,
                  .af-view .af-class-container-small,
                  .af-view .af-class-container-large {
                    margin-right: auto !important;
                    margin-left: auto !important;
                  }
                  .af-view .af-class-text-style-3lines {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                  }
                  .af-view .af-class-text-style-2lines {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  .af-view .af-class-display-inlineflex {
                    display: inline-flex;
                  }
                  .af-view .af-class-hide {
                    display: none !important;
                  }
                  @media screen and (max-width: 991px) {
                    .af-view .af-class-hide,
                    .af-view .af-class-hide-tablet {
                      display: none !important;
                    }
                  }
                  @media screen and (max-width: 767px) {
                    .af-view .af-class-hide-mobile-landscape {
                      display: none !important;
                    }
                  }
                  @media screen and (max-width: 479px) {
                    .af-view .af-class-hide-mobile {
                      display: none !important;
                    }
                  }
                  .af-view .af-class-margin-0 {
                    margin: 0rem !important;
                  }
                  .af-view .af-class-padding-0 {
                    padding: 0rem !important;
                  }
                  .af-view .af-class-spacing-clean {
                    padding: 0rem !important;
                    margin: 0rem !important;
                  }
                  .af-view .af-class-margin-top {
                    margin-right: 0rem !important;
                    margin-bottom: 0rem !important;
                    margin-left: 0rem !important;
                  }
                  .af-view .af-class-padding-top {
                    padding-right: 0rem !important;
                    padding-bottom: 0rem !important;
                    padding-left: 0rem !important;
                  }
                  .af-view .af-class-margin-right {
                    margin-top: 0rem !important;
                    margin-bottom: 0rem !important;
                    margin-left: 0rem !important;
                  }
                  .af-view .af-class-padding-right {
                    padding-top: 0rem !important;
                    padding-bottom: 0rem !important;
                    padding-left: 0rem !important;
                  }
                  .af-view .af-class-margin-bottom {
                    margin-top: 0rem !important;
                    margin-right: 0rem !important;
                    margin-left: 0rem !important;
                  }
                  .af-view .af-class-padding-bottom {
                    padding-top: 0rem !important;
                    padding-right: 0rem !important;
                    padding-left: 0rem !important;
                  }
                  .af-view .af-class-margin-left {
                    margin-top: 0rem !important;
                    margin-right: 0rem !important;
                    margin-bottom: 0rem !important;
                  }
                  .af-view .af-class-padding-left {
                    padding-top: 0rem !important;
                    padding-right: 0rem !important;
                    padding-bottom: 0rem !important;
                  }
                  .af-view .af-class-margin-horizontal {
                    margin-top: 0rem !important;
                    margin-bottom: 0rem !important;
                  }
                  .af-view .af-class-padding-horizontal {
                    padding-top: 0rem !important;
                    padding-bottom: 0rem !important;
                  }
                  .af-view .af-class-margin-vertical {
                    margin-right: 0rem !important;
                    margin-left: 0rem !important;
                  }
                  .af-view .af-class-padding-vertical {
                    padding-right: 0rem !important;
                    padding-left: 0rem !important;
                  }
                ` }} />
              </div>
              <div className="af-class-utility-page_wrapper">
                <img src="https://d3e54v103j8qbb.cloudfront.net/static/page-not-found.211a85e40c.svg" alt="Page Not Found" className="af-class-utility-page_image" />
                <h3>Page Not Found</h3>
                <div>The page you are looking for doesn't exist or has been moved</div>
                <Link id="w-node-_80f0ae4c-c804-42b7-43c9-44e38a9444fa-8a9444f8" to="/" className="af-class-sidebar-menu-item w-inline-block">
                  <div className="af-class-sidemenu-icon w-embed">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.12598 14C8.57002 15.7252 10.1361 17 12 17C13.8638 17 15.4299 15.7252 15.874 14" stroke="CurrentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3 10.5651C3 9.9907 3 9.70352 3.07403 9.43905C3.1396 9.20478 3.24737 8.98444 3.39203 8.78886C3.55534 8.56806 3.78202 8.39175 4.23539 8.03912L11.0177 2.764C11.369 2.49075 11.5447 2.35412 11.7387 2.3016C11.9098 2.25526 12.0902 2.25526 12.2613 2.3016C12.4553 2.35412 12.631 2.49075 12.9823 2.764L19.7646 8.03913C20.218 8.39175 20.4447 8.56806 20.608 8.78886C20.7526 8.98444 20.8604 9.20478 20.926 9.43905C21 9.70352 21 9.9907 21 10.5651V17.8C21 18.9201 21 19.4801 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4801 3 18.9201 3 17.8V10.5651Z" stroke="CurrentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <div>Go home</div>
                </Link>
              </div>
            </div>
          </div>
        </span>
      </span>
    );
  }
}

export default NotFoundView;

/* eslint-enable */