import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import GlobalStyles from '../components/GlobalStyles';
import { Chip, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import useAxiosInstance from '../axiosInstance';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ConfigurationForm from '../components/ConfigurationForm';
import ConfigurationDetailsView from '../components/ConfigurationDetailsView';
import DiscoverView from './DiscoverView';
import { GenerateArtView } from '.';

const mockConversations = {
  "Nike Trends 2024": [
    { role: 'assistant', content: "Would you like to explore other audience?" },
    { role: 'user', content: "Yes" }
  ],
  "Tik Tok Nike": [
    { role: 'user', content: "How does Nike use social media?" },
    { role: 'assistant', content: "Nike uses social media platforms like TikTok and Instagram for targeted marketing and engaging with younger audiences." }
  ],
  "Nike Air Max": [
    { role: 'user', content: "Tell me about Nike Air Max." },
    { role: 'assistant', content: "The Nike Air Max line is renowned for its innovative air cushioning technology and has been a staple in athletic and casual footwear since its debut." }
  ],
  "Create Nike Ad": [
    { role: 'user', content: "How can I create an effective Nike ad?" },
    { role: 'assistant', content: "An effective Nike ad should focus on storytelling, highlighting the brand's commitment to performance and innovation while engaging the audience emotionally." }
  ],
  "How is Nike successful": [
    { role: 'user', content: "What makes Nike successful?" },
    { role: 'assistant', content: "Nike's success is attributed to its strong brand identity, innovative products, effective marketing strategies, and commitment to athletes." }
  ]
};

const ProjectDetailView = () => {
  const {id} = useParams();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [project, setProject] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
    setShowForm(false)
  };
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  const handleGetProject = async (projectId) => {
    try {
      const response =await axiosInstance.get(`/get_project/${projectId}`);
      setProject(response.data.project);
    } catch (error) {
      console.error('Error fetching project', error);
    }
  };

  useEffect(() => {
    handleGetProject(id);
  }, [id]);

  const showCreateForm = (titleText) =>{
    setShowForm(true)
    setFormTitle(titleText)
  }

  return (
    <div className='project-view' style={{ width: "100%", padding : '0px 0 0 24px'}}>
     <GlobalStyles />
      <Stack direction="row" spacing={0} style={{ height: '100%' }} className='stackRow'>
        <div className="section-create-project sectionLeft" style={{ width: "50%", flex: "1", borderRadius: "8px 0 0 8px", marginLeft: '-24px', padding: '15px 30px 15px 30px', background: '#fff', height: '100%'}}>
            <h2 style={{ marginTop: 0, textAlign: "center" }}>{id.replaceAll("-", " ")}</h2>
            <Divider />
            <div className='project-details'>
              <Tabs value={currentTabIndex} onChange={handleTabChange} className='tabs' style={{ margin: "0 0px 15px 0"}}>
                <Tab label='Configuration'/>
                <Tab label='Creatives' />
              </Tabs>
              {currentTabIndex === 0 && (<ConfigurationDetailsView project={project} /> )}
              {currentTabIndex === 1 && (
                <>
                  <div className='content_playbook'>
                    <h2>Current Campaigns</h2>
                    <div className='descp-item'>
                    <h3 className='title-sm'>Marketing Plan Outline</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <ul>
                      <li>
                        <h3 className='title-sm'>Marketing goal</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                      </li>
                      <li>
                        <h3 className='title-sm'>Marketing KPI's</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                      </li>
                      <li>
                        <h3 className='title-sm'>Demographic</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                      </li>
                    </ul>
                  </div>
                    <Timeline style={{ background: '#f8f8f8', padding: '15px'}}>
                    <TimelineItem onClick={() => showCreateForm('Day 1')}>
                      <Chip label="08/24/2024" variant="contained" size='small chip-new' color='secondary'/>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography style={{ fontSize: '14px'}}>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </Typography>
                        <ul className='list-item-disc'>
                            <li>List item 1</li>
                            <li>List item 2</li>
                          </ul>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem  onClick={() => showCreateForm('Day 2')}>
                      <Chip label="08/24/2024" variant="contained" size='small chip-new' color='secondary'/>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                      <Typography style={{ fontSize: '14px'}}>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </Typography>
                        <ul className='list-item-disc'>
                        <li>List item 1</li>
                        <li>List item 2</li>
                      </ul>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem onClick={() => showCreateForm('Day 3')}>
                    <Chip label="08/24/2024" variant="contained" size='small chip-new' color='secondary'/>
                      <TimelineSeparator>
                        <TimelineDot />
                      </TimelineSeparator>
                      <TimelineContent>
                      <Typography style={{ fontSize: '14px'}}>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                  </div>
                </>
                )}
            </div>
        </div>
        <div style={{ padding: '0px 0px', background: "#f8f8f8", width: "50%"}} className='sectionRight'>
            {showForm && <GenerateArtView title={formTitle}/>}
        </div>
      </Stack>
    </div>
  );
};

export default ProjectDetailView;