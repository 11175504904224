import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SignedIn, SignedOut, RedirectToSignIn, UserButton } from "@clerk/clerk-react";
import Sidebar from "./shared/Sidebar";
import ContentWrapper from "./shared/ContentWrapper";
import Navbar from "./components/Navbar";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0e2145',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="main-container flex h_full min_h_full">
        <SignedIn>
          <>
            <Sidebar>
              <Navbar />
            </Sidebar>
            <ContentWrapper />
          </>
        </SignedIn>
        <SignedOut>
          <RedirectToSignIn />
        </SignedOut>
      </div>
    </ThemeProvider>
  );
}

export default App;
