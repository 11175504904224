import React, { useState } from 'react';
import './MarketingPlanForm.css';
import ContentCarousel from './ContentCarousel';
import { contents } from '../components/mockData';
import { Divider, Grid } from '@mui/material';

const MarketingPlanForm = ({ formData, handleChange }) => {
  const [selectedContent, setSelectedContent] = useState(null);

  const handleContentSelect = (content) => {
    setSelectedContent(content);
  };

  return (
    <div className="marketing-plan-form">
      <form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div className="form-group" style={{ margin: 0 }}>
            <label htmlFor="campaignName">Campaign Name</label>
            <input
              type="text"
              id="campaignName"
              name="campaignName"
              value={formData.campaignName}
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={6}>
        <div className="form-group" style={{ margin: 0 }}>
          <label htmlFor="startDate">Start Date</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
          />
        </div>
        </Grid>
        <Grid item xs={6}>
        <div className="form-group" style={{ margin: 0 }}>
          <label htmlFor="endDate">End Date</label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
          />
        </div>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={6}>
        <div className="form-group" style={{ margin: 0 }}>
          <label htmlFor="targetAudience">Target Audience</label>
          <input
            type="text"
            id="targetAudience"
            name="targetAudience"
            value={formData.targetAudience}
            onChange={handleChange}
          />
        </div>
        </Grid>
        <Grid item xs={6}>
        <div className="form-group" style={{ margin: 0 }}>
          <label htmlFor="budget">Budget</label>
          <input
            type="number"
            id="budget"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
          />
        </div>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={6}>
          <div className="form-group" style={{ margin: 0 }}>
            <label htmlFor="objectives">Objectives</label>
            <textarea
              id="objectives"
              name="objectives"
              value={formData.objectives}
              onChange={handleChange}
            ></textarea>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="form-group" style={{ margin: 0 }}>
            <label htmlFor="strategies">Strategies</label>
            <textarea
              id="strategies"
              name="strategies"
              value={formData.strategies}
              onChange={handleChange}
            ></textarea>
          </div>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={6}>
          <div className="form-group" style={{ margin: 0 }}>
            <label htmlFor="tactics">Tactics</label>
            <textarea
              id="tactics"
              name="tactics"
              value={formData.tactics}
              onChange={handleChange}
            ></textarea>
          </div>
        </Grid>
        <Grid item xs={6}>
        <div className="form-group" style={{ margin: 0 }}>
          <label htmlFor="metrics">Metrics</label>
          <textarea
            id="metrics"
            name="metrics"
            value={formData.metrics}
            onChange={handleChange}
          ></textarea>
        </div>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
      </Grid>
      </form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ContentCarousel videos={contents} onSelect={handleContentSelect} />
        </Grid>
      </Grid>
      {/* {selectedVideo && (
        <div className="selected-video">
          <h3>Selected Video</h3>
          <video src={selectedVideo.url} width="400" controls />
          <p>{selectedVideo.title}</p>
        </div>
      )} */}
    </div>
  );
};

export default MarketingPlanForm;
