import { Stack } from "@mui/material";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import MarketingPlanModal from "./MarketingPlanModal";
import MarketingPlanForm from "./MarketingPlanForm";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosInstance from "../axiosInstance";

function MarketingPlanBar() {
  const mockConversations = {
    "Nike Trends 2024": [
      { role: "user", content: "What's the latest trend in Nike shoes?" },
      {
        role: "assistant",
        content:
          "The latest trend in Nike shoes includes the new Nike Air Max 2024 with advanced cushioning and bold new designs.",
      },
    ],
    "Tik Tok Nike": [
      { role: "user", content: "How does Nike use social media?" },
      {
        role: "assistant",
        content:
          "Nike uses social media platforms like TikTok and Instagram for targeted marketing and engaging with younger audiences.",
      },
    ],
    "Nike Air Max": [
      { role: "user", content: "Tell me about Nike Air Max." },
      {
        role: "assistant",
        content:
          "The Nike Air Max line is renowned for its innovative air cushioning technology and has been a staple in athletic and casual footwear since its debut.",
      },
    ],
    "Create Nike Ad": [
      { role: "user", content: "How can I create an effective Nike ad?" },
      {
        role: "assistant",
        content:
          "An effective Nike ad should focus on storytelling, highlighting the brand's commitment to performance and innovation while engaging the audience emotionally.",
      },
    ],
    "How is Nike successful": [
      { role: "user", content: "What makes Nike successful?" },
      {
        role: "assistant",
        content:
          "Nike's success is attributed to its strong brand identity, innovative products, effective marketing strategies, and commitment to athletes.",
      },
    ],
  };
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { query, checkedContents, project_id } = location.state || {};
  const { project } = location.state || {};
  const [selectedProject, setSelectedProject] = useState(
    project?.project_name || ""
  );
  const [selectedThread, setSelectedThread] = useState("");
  const [messages, setMessages] = useState(
    mockConversations["Nike Trends 2024"] || []
  );
  const [formData, setFormData] = useState({
    campaignName: "",
    startDate: "",
    endDate: "",
    targetAudience: "",
    budget: "",
    objectives: "",
    strategies: "",
    tactics: "",
    metrics: "",
  });
  const navigate = useNavigate();
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    if (project_id) {
      fetchProjectMessages(project_id);
    }
  }, [project_id]);

  const fetchProjectMessages = async (projectId) => {
    try {
      const response = await axiosInstance.get(
        `/get_project_messages/${projectId}`
      );
      setMessages(response.data.messages);
      setSelectedProject(response.data.project.project_name);
    } catch (error) {
      console.error("Error fetching project messages:", error);
    }
  };

  const handleCreateCampaign = () => {
    navigate("/generate-art-with-plan", { state: formData });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectProject = (projectId) => {
    setSelectedProject(projectId);
    fetchProjectMessages(projectId);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="marketing_plan">
      <Stack direction="row" spacing={2}>
        <h3 className="title">Marketing Plan</h3>
        <Button
          style={{ margin: "0 0 0 auto" }}
          variant="contained"
          className="create-btn"
          color="primary"
          sx={{ p: "10px" }}
          aria-label="directions"
          onClick={openModal}
        >
          Create Plan ✨
        </Button>
      </Stack>
      <MarketingPlanModal isOpen={isModalOpen} onClose={closeModal}>
        <MarketingPlanForm formData={formData} handleChange={handleChange} />
        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCreateCampaign}
            className="af-class-button af-class-is-small"
            style={{ margin: "0" }}
          >
            Campaign 1 (active)
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateCampaign}
            className="af-class-button af-class-is-small"
            style={{ margin: "0px 0px 0px 10px" }}
          >
            Campaign 2 (non-active)
          </Button>
        </div>
      </MarketingPlanModal>
    </div>
  );
}

export default MarketingPlanBar;
