/* eslint-disable */

import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import TurnedInNotOutlinedIcon from '@mui/icons-material/TurnedInNotOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import PublicIcon from '@mui/icons-material/Public';


function FacebookPreview({ content }) {
    return (
        <div style={{ transform: "scale(0.684564)", transformOrigin: "center top" }}>
        <div style={{ height: "50px", textAlign: "center"}}>
            <img src='../images/Facebook-Logo.png' alt='Facebook' style={{ height: "50px"}} />
        </div>
        <Card>
            <CardHeader 
                style={{paddingBottom: '0'}}
                title={
                    <div style={{ display: 'flex', width: '100%'}}>
                        <span style={{ width: '32px', height: '32px', borderRadius: '50%', background:'#9c27b0', display: 'inline-block', textAlign:'center',  color:'#fff', marginRight: '10px', lineHeight: '32px', fontSize: '20px'}}>
                            <img src='../images/NikeLogo.jpg' alt='logo' style={{  borderRadius: '50%'}}/>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'column'}}>
                            <span style={{ fontSize: '16px', fontWeight: 'bold'}}>nike</span>
                            <span style={{ fontSize: '12px', color: '#90949c'}}>Sponsored <PublicIcon style={{ color: '#90949c', fontSize: '12px'}}/></span>
                        </span>
                        <span style={{ marginLeft: 'auto'}}><MoreHorizIcon style={{ fontSize: '18px'}}/></span>
                    </div>
                }
            />
            <CardContent style={{ paddingRight: '0', paddingLeft: '0' }}>
                <div className='content-builder-img' style={{ marginBottom: "0px" }}>
                    <div style={{ padding: '0px 15px 15px'}}><Typography style={{ fontSize: '14px'}}>{content.content}</Typography></div>
                    <img src={content.media_url} loading='lazy' />
                    <div style={{ padding: '15px 15px 0px'}}>
                        <ul style={{ listStyle: 'none', display: 'flex', justifyContent: "space-between", paddingLeft: '0', alignItems: 'center', margin: 0}}>
                            <li style={{ display: 'flex', alignItems: 'center', textAlign: 'left', color: '#616770'}}><ThumbUpOutlinedIcon  style={{ fontSize: '22px'}} /><span style={{ marginLeft: '5px', fontWeight: 'bold'}}>Like</span></li>
                            <li style={{  display: 'flex', alignItems: 'center', textAlign: 'left', marginLeft: '15px',  color: '#616770'}}><ChatBubbleOutlineOutlinedIcon  style={{ fontSize: '20px'}} /><span  style={{ marginLeft: '5px', fontWeight: 'bold'}}>Comment</span></li>
                            <li style={{  display: 'flex', alignItems: 'center', color: '#616770'}}><ReplyIcon  style={{ fontSize: '22px',   transform: 'scaleX(-1)'}} /><span  style={{ marginLeft: '5px', fontWeight: 'bold'}}>Share</span></li>
                        </ul>
                    </div>
                </div>
            </CardContent>
        </Card>
        </div>
    )
}

function InstagramPreview({ content }) {
    return (
        <div style={{ transform: "scale(0.684564)", transformOrigin: "center top" }}>
        <div style={{ height: "50px", textAlign: "center"}}>
            <img src='../images/Instagram_logo.png' alt='Facebook' style={{ height: "50px", transform: "scale(0.8)", transformOrigin: "center center"}} />
        </div>
        <Card>
            <CardHeader
                style={{paddingBottom: '0'}}
                title={
                    
                    <div style={{ display: 'flex', width: '100%'}}>
                        <span style={{ width: '32px', height: '32px', borderRadius: '50%', background:'#9c27b0', display: 'inline-block', textAlign:'center',  color:'#fff', marginRight: '10px', lineHeight: '32px', fontSize: '20px'}}>
                            <img src='../images/NikeLogo.jpg' alt='logo' style={{  borderRadius: '50%'}}/>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'column'}}>
                            <span style={{ fontSize: '16px', fontWeight: 'bold'}}>nike</span>
                            <span style={{ fontSize: '12px'}}>Sponsored</span>
                        </span>
                        <span style={{ marginLeft: 'auto'}}><MoreHorizIcon style={{ fontSize: '18px'}}/></span>
                    </div>
                }
            />
            <CardContent style={{ paddingRight: '0', paddingLeft: '0' }}>
                <div className='content-builder-img' style={{ marginBottom: "0px" }}>
                    <img src={content.media_url} loading='lazy' />
                    <div style={{ padding: '15px 15px 0px'}}>
                        <ul style={{ listStyle: 'none', display: 'flex', paddingLeft: '0', alignItems: 'center'}}>
                            <li style={{ marginRight: '10px'}}><FavoriteBorderIcon  style={{ fontSize: '22px'}} /></li>
                            <li style={{ marginRight: '10px'}}><MapsUgcOutlinedIcon style={{ fontSize: '22px'}} /></li>
                            <li><SendOutlinedIcon style={{ fontSize: '22px'}}/></li>
                            <li style={{ marginLeft: 'auto'}}><TurnedInNotOutlinedIcon style={{ fontSize: '22px'}}/></li>
                        </ul>
                        <span className='brandName' style={{ fontWeight: 'bold' }}>nike</span><Typography style={{ fontSize: '14px'}}>{content.content}</Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
        </div>
    )
}

const EntryPostPreview = (props) => {

    return (
        <Stack direction={"row"}>
            <FacebookPreview content={props.content} />
            <InstagramPreview content={props.content} />
        </Stack>
    )
}

export default EntryPostPreview;

/* eslint-enable */