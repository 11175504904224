import React, { useEffect, useState } from "react";
import GlobalStyles from "../components/GlobalStyles";
import ContentCard from "../components/ContentCard";
import ContentModal from "../components/ContentModal";
import "../components/ContentCard.css";
import "../components/ContentModal.css";
import "../components/MetadataTable.css";
import useAxiosInstance from "../axiosInstance";
import HomeSearch from "../components/HomeSearch";
import HomeContentModal from "../components/HomeContentModal";
import { useParams } from "react-router-dom";

const SearchView = () => {
  const { query } = useParams();
  const [searchQuery, setSearchQuery] = useState(query);
  const [selectedContent, setSelectedContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contents, setContents] = useState("");
  const [checkedContents, setCheckedContents] = useState([]);
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axiosInstance.get(`/get_content`);
        setContents(response.data.content);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, [searchQuery]);


  const handleCardClick = (item) => {
    setSelectedContent(item);
    setIsModalOpen(true);
  };

  const handleCheckboxChange = (content, isChecked) => {
    setCheckedContents((prev) => {
      if (isChecked) {
        return [...prev, content];
      } else {
        return prev.filter((item) => item.id !== content.id);
      }
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedContent(null);
  };

  const handleUploadSuccess = (newContent) => {
    setContents([newContent, ...contents]);
  };

  const handleSearchResult = (data) => {
    setContents(data);
  };

  return (
    <div className="content_box">
      <GlobalStyles />
      <HomeSearch
        handleContentUpdate={handleUploadSuccess}
        handleSearchResult={handleSearchResult}
      />
      <div className="content-grid">
        {contents.length > 0 ? (
          contents.map((content) => (
            <ContentCard
              key={content.id}
              content={content}
              onClick={() => handleCardClick(content)}
              onCheckboxChange={handleCheckboxChange}
            />
          ))
        ) : (
          <div>No videos found</div>
        )}
      </div>
    </div>
  );
};

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default SearchView;
