import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import GlobalStyles from "../components/GlobalStyles";
import { Divider, Stack, Tab, Tabs } from "@mui/material";
import ConfigurationDetailsView from "../components/ConfigurationDetailsView";
import TimeLine from "../components/TimeLine";
import ContentBuilder from "../components/ContentBuilder";
import PlaybookConfiguration from "../components/playbookConfiguration";
import useAxiosInstance from "../axiosInstance";
import { entryDetails, genPlaybookMockData, genPlaybookMockData2, genPlaybookMockData3, genPlaybookMockData4, playbookMockData } from "../mock-data/playbookMockData";
import AddCalendarEntry from "../components/AddCalendarEntry";
import AjaxLoader from "../components/AjaxLoader";
import { nikeShoesEntryDetails, nikeShoesEntryDetailsRefresh, nikeShoesMockData } from "../mock-data/nikeShoesMockData";

const AddViewPlaybook = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("activeTab");
  const [currentTabIndex, setCurrentTabIndex] = useState(
    parseInt(activeTab) ?? 0
  );
  const [formTitle, setFormTitle] = useState("Content");
  const [playbookDetails, setPlaybookDetails] = useState(undefined);
  const [calendarDetails, setCalendarDetails] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [mockPlayBookDetails, setMockPlayBookDetails] = useState(undefined);
  const [showLoader, setShowLoader] = useState(undefined);
  const axiosInstance = useAxiosInstance();
  const mockDataSets = [
    genPlaybookMockData,
    genPlaybookMockData2,
    genPlaybookMockData3,
    genPlaybookMockData4,
  ]
  const [currentIndex, setCurrentIndex] = useState(0);
  const [contentLoader, setContentLoader] = useState(undefined);
  const [showAddContentDialog, setShowAddContentDialog] = useState(false);
  
  // const getCalendarEntry = async (id) => {
  //   setLoading(true);
  //   try {
  //     const response = await axiosInstance.get(`/get_calendar_entry/${id}`);
  //     setCalendarDetails(response.data);
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //   }
  // };

  const getEntryDetails = (id) => {
    setLoading(true)
    setTimeout(() => {
      const calendarEntries = nikeShoesEntryDetails;
      const filterData = calendarEntries.filter((item) => item.id === id)
      setCalendarDetails({ entry: filterData[0]})
      setLoading(false)
    }, 2000);
  }

  const fetchPlaybook = async () => {
    try {
      //const response = await axiosInstance.get(`/get_playbook/${id}`);
      const playbookDetails = nikeShoesMockData.playbook;
      const content_calendar = nikeShoesMockData.playbook.content_calendar[0];
      getEntryDetails(content_calendar.entries[0].id);
      setPlaybookDetails(playbookDetails)
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlaybook();
  }, [id]);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const showCreateForm = (titleText, item) => {
    setFormTitle(titleText);
    getEntryDetails(item.id);
  };

  const handleDeleteCalendarEntry = (selectedEntryId) => {
    let newPlaybookDetails = { ...playbookDetails };
    let entries = newPlaybookDetails.content_calendar.entries;
    entries = entries.filter((entry) => entry.id !== selectedEntryId);
    newPlaybookDetails.content_calendar.entries = entries;
    setPlaybookDetails(newPlaybookDetails);
    if (selectedEntryId === calendarDetails.entry.id) {
      setCalendarDetails(undefined);
    }
  };

  const handleGenerateNewPlayBook = () => {
    // Call Generate New Content Calendar API here and replace response with mockdata
    handleToggle();
    setShowLoader(true);
    setTimeout(() => {
      setMockPlayBookDetails(mockDataSets[currentIndex].playbook);
      setShowLoader(false);
    }, 3000);
  };

  const handleToggle = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mockDataSets.length);
  };

  const openCreateContentDialog = () =>{
    setShowAddContentDialog(true)
  }

  const closeCreateContentDialog = () =>{
    setShowAddContentDialog(false)
  }

  const handleCreateContent = (data) => {
    console.log('data', data)
    setContentLoader(true)
    setTimeout(() => {
      if(currentTabIndex === 0){
        let newMockPlayBookDetails = { ...mockPlayBookDetails }
        newMockPlayBookDetails.content_calendar[0].entries.push(data)
        setMockPlayBookDetails(newMockPlayBookDetails)
      }
      else{
        let newPlaybookDetails = { ...playbookDetails }
        newPlaybookDetails.content_calendar[0].entries.push(data)
        setPlaybookDetails(newPlaybookDetails)
      }
      setShowAddContentDialog(false)
      setContentLoader(false)
    }, 2000);
  }

  const refreshDayEntry = id => {
    setLoading(true)
    setTimeout(() => {
      const refreshEntry = nikeShoesEntryDetailsRefresh
      setCalendarDetails({ entry: {...refreshEntry, id: id} })
      let newPlaybookDetails = { ...playbookDetails }
      const index = newPlaybookDetails.content_calendar[0].entries.findIndex(elem => elem.id === id)
      newPlaybookDetails.content_calendar[0].entries[index] = refreshEntry
      setPlaybookDetails(newPlaybookDetails)
      setLoading(false)
    }, 2000);
    
  }
  console.log('calendarDetails', calendarDetails)
  return (
    <div
      className="project-view"
      style={{ width: "100%", padding: "0px 0 0 24px" }}
    >
      <GlobalStyles />
      <Stack
        direction="row"
        spacing={0}
        style={{ height: "100%" }}
        className="stackRow"
      >
        <div
          className="section-create-project sectionLeft"
          style={{
            width: "50%",
            borderRadius: "8px 0 0 8px",
            marginLeft: "-24px",
            padding: "15px 30px 15px 30px",
            background: "#fff",
            height: "100%",
          }}
        >
          {playbookDetails ? (
            <>
              <h2
                style={{ marginTop: 0, textAlign: "center", fontWeight: "300" }}
              >
                {id ? id : "New Playbook"}
              </h2>
              <Divider />
              <div className="project-details">
                <Tabs
                  value={currentTabIndex}
                  onChange={handleTabChange}
                  className="tabs"
                  style={{ margin: "0 0px 15px 0" }}
                >
                  <Tab label="Configuration" />
                  <Tab label="Content" />
                </Tabs>
                {currentTabIndex === 0 && (
                  <div className="playbook-configuration">
                    <PlaybookConfiguration
                      playbookDetails={playbookDetails}
                      handleGenerateNewPlayBook={handleGenerateNewPlayBook}
                    />
                  </div>
                )}
                {currentTabIndex === 1 && (
                  <div className="playbook-content">
                    <TimeLine
                      playbookDetails={playbookDetails}
                      showCreateForm={showCreateForm}
                      handleDeleteCalendarEntry={handleDeleteCalendarEntry}
                      openCreateContent={openCreateContentDialog}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            "Playbook Not Found"
          )}
        </div>
        <div
          style={{ padding: "0px 0px", background: "#f8f8f8", width: "50%" }}
          className="sectionRight"
        >
          {playbookDetails && (
            <>
              {currentTabIndex === 0 && (
                <ContentBuilder
                  source={"PLANNER"}
                  playbookDetails={playbookDetails}
                  mockPlayBookDetails={mockPlayBookDetails}
                  loader={showLoader}
                  openCreateContent={openCreateContentDialog}
                  onClose={closeCreateContentDialog} 
                  handleCreateContent={handleCreateContent}
                />
              )}
              {currentTabIndex === 1 && (
                <ContentBuilder
                  source={"CONTENT"}
                  title={formTitle}
                  entryDetails={calendarDetails}
                  loading={loading}
                  refreshDayEntry={refreshDayEntry}
                />
              )}
            </>
          )}
        </div>
      </Stack>
      {contentLoader && <AjaxLoader open={contentLoader} />}
      {showAddContentDialog && <AddCalendarEntry open={showAddContentDialog} onClose={closeCreateContentDialog} handleCreateContent={handleCreateContent} />}
    </div>
  );
};

export default AddViewPlaybook;
