// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkConfig = {
  publishableKey: PUBLISHABLE_KEY,
  auth: {
    redirectTo: '/sign-in',
  },
};

export default clerkConfig;
