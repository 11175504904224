import React, { useState } from 'react';
import './JsonToTable.css';
import { Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const propName = parent ? `${parent}.${key}` : key;
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                flattenObject(obj[key], propName, res);
            } else if (Array.isArray(obj[key])) {
                res[propName] = obj[key].map((item) => 
                    typeof item === 'object' ? JSON.stringify(item) : item
                ).join(', ');
            } else {
                res[propName] = obj[key];
            }
        }
    }
    return res;
};

const JsonToTable = ({ data }) => {
    const [selectedMainTab, setSelectedMainTab] = useState('Summary');
    const [selectedSocialTab, setSelectedSocialTab] = useState('TikTok');

    if (!data) return null;

    const flattenedData = selectedMainTab === 'Socials' && selectedSocialTab === 'TikTok'
        ? flattenObject(data[selectedMainTab][selectedSocialTab])
        : selectedMainTab !== 'Socials'
        ? flattenObject(data[selectedMainTab])
        : {};

    const handleChange = (key) =>{
        setSelectedMainTab(key);
        if (key !== 'Socials') setSelectedSocialTab('TikTok');
    }
    return (
        <div className="json-to-table">
            <div className="tabs" style={{ justifyContent: 'normal'}}>
                {Object.keys(data).map((key) => (
                    <Button
                        key={key}
                        className={`tab-button ${selectedMainTab === key ? 'active' : ''}`}
                        onClick={() => {
                            setSelectedMainTab(key);
                            if (key !== 'Socials') setSelectedSocialTab('TikTok');
                        }}
                        style={{ marginRight: '10px'}}
                    >
                        {key}
                    </Button>
                ))}
            </div>

            {selectedMainTab === 'Socials' && (
                <div className="nested-tabs">
                    {Object.keys(data.Socials).map((key) => (
                        <Button
                            key={key}
                            className={`tab-button ${selectedSocialTab === key ? 'active' : ''}`}
                            onClick={() => setSelectedSocialTab(key)}
                            // disabled={key !== 'TikTok'}
                        >
                            {key}
                        </Button>
                    ))}
                </div>
            )}

            <div className="table-container no-border" style={{ marginTop: '20px'}}>
                <table>
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(flattenedData).length > 0 ? (
                            Object.keys(flattenedData).map((key, index) => (
                                <tr key={index}>
                                    <td className="json-key">{key}</td>
                                    <td className="json-value">{flattenedData[key]}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className="no-data">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default JsonToTable;