import axios from "axios";
import { useUser } from "@clerk/clerk-react";

// Create a function to set up axios with headers
const useAxiosInstance = () => {
  const { isLoaded, isSignedIn, user } = useUser();

  const baseURL = `${process.env.REACT_APP_API_URL}`;
  const token = `${process.env.REACT_APP_SEC_KEY}`;

  // Create an axios instance
  const axiosInstance = axios.create({
    baseURL,
  });

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      // Add authorization header
      config.headers["Authorization"] = `Bearer ${token}`;

      // Add user ID to headers if the user is signed in
      if (isLoaded && isSignedIn && user) {
        config.headers["User-ID"] = user.id;
      }

      return config;
    },
    (error) => {
      // Handle request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor (optional)
  axiosInstance.interceptors.response.use(
    (response) => {
      // Handle response data
      return response;
    },
    (error) => {
      // Handle response error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxiosInstance;
