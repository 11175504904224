import React from 'react';

const GlobalStyles = () => (
  <style dangerouslySetInnerHTML={{ __html: `
    @import url('/css/normalize.css');
    @import url('/css/webflow.css');
    @import url('/css/devs-fantabulous-site-547be8.webflow.css');

    .af-view {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
    }
    .af-view *[tabindex]:focus-visible,
    .af-view input[type="file"]:focus-visible {
      outline: 0.125rem solid #4d65ff;
      outline-offset: 0.125rem;
    }
    .af-view .w-richtext > :not(div):first-child, 
    .af-view .w-richtext > div:first-child > :first-child {
      margin-top: 0 !important;
    }
    .af-view .w-richtext>:last-child,
    .af-view .w-richtext ol li:last-child,
    .af-view .w-richtext ul li:last-child {
      margin-bottom: 0 !important;
    }
    .af-view .af-class-pointer-events-off {
      pointer-events: none;
    }
    .af-view .af-class-pointer-events-on {
      pointer-events: auto;
    }
    .af-view .af-class-div-square::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    .af-view .af-class-container-medium,
    .af-view .af-class-container-small,
    .af-view .af-class-container-large {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .af-view .af-class-text-style-3lines {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .af-view .af-class-text-style-2lines {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .af-view .af-class-display-inlineflex {
      display: inline-flex;
    }
    .af-view .af-class-hide {
      display: none !important;
    }
    @media screen and (max-width: 991px) {
      .af-view .af-class-hide,
      .af-view .af-class-hide-tablet {
        display: none !important;
      }
    }
    @media screen and (max-width: 767px) {
      .af-view .af-class-hide-mobile-landscape {
        display: none !important;
      }
    }
    @media screen and (max-width: 479px) {
      .af-view .af-class-hide-mobile {
        display: none !important;
      }
    }
    .af-view .af-class-margin-0 {
      margin: 0rem !important;
    }
    .af-view .af-class-padding-0 {
      padding: 0rem !important;
    }
    .af-view .af-class-spacing-clean {
      padding: 0rem !important;
      margin: 0rem !important;
    }
    .af-view .af-class-margin-top {
      margin-right: 0rem !important;
      margin-bottom: 0rem !important;
      margin-left: 0rem !important;
    }
    .af-view .af-class-padding-top {
      padding-right: 0rem !important;
      padding-bottom: 0rem !important;
      padding-left: 0rem !important;
    }
    .af-view .af-class-margin-right {
      margin-top: 0rem !important;
      margin-bottom: 0rem !important;
      margin-left: 0rem !important;
    }
    .af-view .af-class-padding-right {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
      padding-left: 0rem !important;
    }
    .af-view .af-class-margin-bottom {
      margin-top: 0rem !important;
      margin-right: 0rem !important;
      margin-left: 0rem !important;
    }
    .af-view .af-class-padding-bottom {
      padding-top: 0rem !important;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }
    .af-view .af-class-margin-left {
      margin-top: 0rem !important;
      margin-right: 0rem !important;
      margin-bottom: 0rem !important;
    }
    .af-view .af-class-padding-left {
      padding-top: 0rem !important;
      padding-right: 0rem !important;
      padding-bottom: 0rem !important;
    }
    .af-view .af-class-margin-horizontal {
      margin-top: 0rem !important;
      margin-bottom: 0rem !important;
    }
    .af-view .af-class-padding-horizontal {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
    .af-view .af-class-margin-vertical {
      margin-right: 0rem !important;
      margin-left: 0rem !important;
    }
    .af-view .af-class-padding-vertical {
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }
      
    .video-list {
      padding: 20px;
    }

    .video-list-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      list-style-type: none;
      padding: 0;
    }

    .video-list-item {
      background: #f9f9f9;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
    }
    .chat-form {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
      
    .messages {
        height: 250px;
        overflow-y: auto;
        padding: 10px;
        border-bottom: 1px solid #ccc;
    }
      
    .message {
        display: flex;
        align-items: center;
        margin: 10px 0;
        padding: 10px;
        border-radius: 5px;
    }
      
    .message.user {
        background-color: #e0f7fa;
        justify-content: flex-end;
    }
      
    .message.assistant {
        background-color: #f1f8e9;
        justify-content: flex-start;
    }
      
    .icon {
        margin: 0 10px;
    }
      
    .user-icon {
        color: #007bff;
    }
      
    .robot-icon {
        color: #28a745;
    }
      
    .message-content {
        max-width: 70%;
    }
      
    .loading {
        text-align: center;
        color: #666;
        font-style: italic;
    }
      
    

    .button-container {
        display: flex;
        gap: 10px; /* Adjust the gap as needed */
        margin-left: auto; /* Push buttons to the right */
    }
      
    input[type="text"] {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
    }
      
    button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color: #007bff;
        color: #fff;
        cursor: pointer;
    }
      
    button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
    /* General Page Styling */
    .page, .discover-page, .home-page, .generate-ad-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      font-family: Arial, sans-serif;
    }

    h1 {
      font-size: 2.5em;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 1.5em;
      margin-bottom: 15px;
    }

    /* Common Container Styling */
    .app {
      display: flex;
      width: 100%;
      max-width: 1200px;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }

    /* Column Styling */
    .column {
      padding: 20px;
      border-right: 1px solid #ddd;
    }

    .column:last-child {
      border-right: none;
    }

    

    /* Chat Sections */
    .small-chat {
      flex: 1;
      background-color: #f8f8f8;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .large-chat {
      flex: 3;
      background-color: #f8f8f8;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    /* Right Column for Generate Ad */
    .right {
      flex: 3;
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    /* Form and Input Styling */
    .form-group {
      margin-bottom: 20px;
    }

    .label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .input, .form-control, .button-group, .input-field {
      width: 100%;
      margin-bottom: 10px;
    }

    textarea.form-control {
      height: 100px;
    }

    .button-group {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    .input-field {
      padding: 10px;
      font-size: 1em;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 250px;
    }

    /* Button Styling */
    .button {
      padding: 10px 20px;
      font-size: 1em;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    .search-button {
      background-color: #007bff;
      color: white;
    }

    .new-thread-button {
      background-color: #28a745;
      color: white;
    }

    /* Loading and Video Container Styling */
    .loading {
      font-size: 1.2em;
      color: #333;
    }

    .video-container {
      margin-top: 20px;
      text-align: center;
    }

    /* Home Page Specific Styling */
    .video-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 800px;
      margin-bottom: 20px;
    }

    .video-card {
      background-color: #f8f8f8;
      padding: 20px;
      margin-bottom: 15px;
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      text-align: left;
    }

    .video-card h2 {
      margin: 0 0 10px 0;
      font-size: 1.5em;
    }

    .video-card p {
      margin: 0;
      color: #555;
    }

    .settings {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 800px;
      margin-bottom: 20px;
    }

    /* Prompt Section Styling */
    .prompt-section {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    /* Modal Styles */
    .copy-modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      background: white;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
      width: 300px;
    }

    .copy-modal h4 {
      margin-bottom: 15px;
    }

    .copy-modal .btn {
      margin: 5px;
    }

    /* Backdrop Styles */
    .modal-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }

    /* Additional Styling */
    .copy-modal .btn {
      padding: 10px 20px;
      cursor: pointer;
      border: none;
      border-radius: 5px;
    }

    .copy-modal .btn-primary {
      background-color: #007bff;
      color: white;
    }

    .copy-modal .btn-secondary {
      background-color: #6c757d;
      color: white;
    }

    /* Close Button Styles */
    .copy-modal .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
    }

    /* Additional Styling */
    .copy-modal .btn {
      padding: 10px 20px;
      cursor: pointer;
      border: none;
      border-radius: 5px;
    }

    .copy-modal .btn-primary {
      background-color: #007bff;
      color: white;
    }

    .copy-modal .btn-secondary {
      background-color: #6c757d;
      color: white;
    }

    /* ThreadList.css */

    .thread-list-dropdown {
      padding-left: 20px;
      width: 75%;
    }

    

    .input-with-button {
      display: flex;
      align-items: center;
    }

    .input-with-button .form-control {
      flex-grow: 1;
    }

    .generate-button {
      margin-left: 10px;
    }
      
  ` }} />
);

export default GlobalStyles;