import React from "react";
import { Link } from "react-router-dom";
import { SignedIn, SignedOut, RedirectToSignIn, UserButton } from "@clerk/clerk-react";

const Navbar = () => {
    return (
        <div data-animation="default" className="af-class-navbar w-nav" data-easing2="ease" fs-scrolldisable-element="smart-nav" data-easing="ease" data-collapse="medium" data-w-id="46a1a930-a937-37fe-bcca-d83df445cdbd" role="banner" data-duration={400}>
            <div className="af-class-navbar-container">
                <Link to="/" className="af-class-navbar2_logo-link w-nav-brand">
                    <img src="/images/byteroll-logo-black.png" loading="lazy" alt className="af-class-navbar2_logo af-class-desktop" />
                    <img src="/images/Logo-1.png" loading="lazy" alt className="af-class-navbar2_logo af-class-show-mobile-only" style={{ display: 'none'}}/>
                </Link>
                <img />
                <div id="w-node-_46a1a930-a937-37fe-bcca-d83df445cdc9-f445cdbd" className="af-class-navbar2_button-wrapper">
                    <UserButton />
                    {/* <Link to="/generate-art" className="af-class-button af-class-is-navbar2-button af-class-gradient-background w-button">Create ✨</Link>
                    <Link to="/account-settings" aria-current="page" className="af-class-profile-link w-inline-block w--current">
                        <img src="/images/manny-profile.png" loading="lazy" alt className="af-class-profile-link-image" />
                    </Link> */}
                </div>
            </div>
        </div>
    );
};

export default Navbar;