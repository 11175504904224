import React from 'react';
import { GenerateArtView } from "../views";

function ConfigurationDetailsView () {
    return(
        <>
            <GenerateArtView />
        </>
       
    )
}

export default ConfigurationDetailsView;