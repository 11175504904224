/* eslint-disable */

import React from 'react';
import { Link } from 'react-router-dom';
import { createScope, map, transformProxies } from './helpers';

const scripts = [
  { loading: Promise.resolve("/[?&]e=1(&|$)/.test(document.location.search)&&(document.querySelector(\".w-password-page.w-form-fail\").style.display=\"block\");"), isAsync: false },
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6671e61719d3443cc0ddd63c").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class UnauthorizedView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/UnauthorizedController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = UnauthorizedView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6671e61719d3443cc0ddd675'
    htmlEl.dataset['wfSite'] = '6671e61719d3443cc0ddd63c'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = UnauthorizedView.Controller !== UnauthorizedView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(C:\\css\\normalize.css);
          @import url(C:\\css\\webflow.css);
          @import url(C:\\css\\devs-fantabulous-site-547be8.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <div className="af-class-global-styles w-embed">
                <style dangerouslySetInnerHTML={{ __html: `
                  .af-view {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-smoothing: antialiased;
                    text-rendering: optimizeLegibility;
                  }
                  .af-view *[tabindex]:focus-visible,
                  .af-view input[type="file"]:focus-visible {
                    outline: 0.125rem solid #4d65ff;
                    outline-offset: 0.125rem;
                  }
                  .af-view .w-richtext > :not(div):first-child, 
                  .af-view .w-richtext > div:first-child > :first-child {
                    margin-top: 0 !important;
                  }
                  .af-view .w-richtext>:last-child,
                  .af-view .w-richtext ol li:last-child,
                  .af-view .w-richtext ul li:last-child {
                    margin-bottom: 0 !important;
                  }
                  .af-view .af-class-pointer-events-off {
                    pointer-events: none;
                  }
                  .af-view .af-class-pointer-events-on {
                    pointer-events: auto;
                  }
                  .af-view .af-class-div-square::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                  }
                  .af-view .af-class-container-medium,
                  .af-view .af-class-container-small,
                  .af-view .af-class-container-large {
                    margin-right: auto !important;
                    margin-left: auto !important;
                  }
                  .af-view .af-class-text-style-3lines {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                  }
                  .af-view .af-class-text-style-2lines {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  .af-view .af-class-display-inlineflex {
                    display: inline-flex;
                  }
                  .af-view .af-class-hide {
                    display: none !important;
                  }
                  @media screen and (max-width: 991px) {
                    .af-view .af-class-hide,
                    .af-view .af-class-hide-tablet {
                      display: none !important;
                    }
                  }
                  @media screen and (max-width: 767px) {
                    .af-view .af-class-hide-mobile-landscape {
                      display: none !important;
                    }
                  }
                  @media screen and (max-width: 479px) {
                    .af-view .af-class-hide-mobile {
                      display: none !important;
                    }
                  }
                  .af-view .af-class-margin-0 {
                    margin: 0rem !important;
                  }
                  .af-view .af-class-padding-0 {
                    padding: 0rem !important;
                  }
                  .af-view .af-class-spacing-clean {
                    padding: 0rem !important;
                    margin: 0rem !important;
                  }
                  .af-view .af-class-margin-top {
                    margin-right: 0rem !important;
                    margin-bottom: 0rem !important;
                    margin-left: 0rem !important;
                  }
                  .af-view .af-class-padding-top {
                    padding-right: 0rem !important;
                    padding-bottom: 0rem !important;
                    padding-left: 0rem !important;
                  }
                  .af-view .af-class-margin-right {
                    margin-top: 0rem !important;
                    margin-bottom: 0rem !important;
                    margin-left: 0rem !important;
                  }
                  .af-view .af-class-padding-right {
                    padding-top: 0rem !important;
                    padding-bottom: 0rem !important;
                    padding-left: 0rem !important;
                  }
                  .af-view .af-class-margin-bottom {
                    margin-top: 0rem !important;
                    margin-right: 0rem !important;
                    margin-left: 0rem !important;
                  }
                  .af-view .af-class-padding-bottom {
                    padding-top: 0rem !important;
                    padding-right: 0rem !important;
                    padding-left: 0rem !important;
                  }
                  .af-view .af-class-margin-left {
                    margin-top: 0rem !important;
                    margin-right: 0rem !important;
                    margin-bottom: 0rem !important;
                  }
                  .af-view .af-class-padding-left {
                    padding-top: 0rem !important;
                    padding-right: 0rem !important;
                    padding-bottom: 0rem !important;
                  }
                  .af-view .af-class-margin-horizontal {
                    margin-top: 0rem !important;
                    margin-bottom: 0rem !important;
                  }
                  .af-view .af-class-padding-horizontal {
                    padding-top: 0rem !important;
                    padding-bottom: 0rem !important;
                  }
                  .af-view .af-class-margin-vertical {
                    margin-right: 0rem !important;
                    margin-left: 0rem !important;
                  }
                  .af-view .af-class-padding-vertical {
                    padding-right: 0rem !important;
                    padding-left: 0rem !important;
                  }
                ` }} />
              </div>
              <div className="af-class-utility-page_wrapper w-password-page w-form">
                <form action="/.wf_auth" method="post" id="email-form" name="email-form" data-name="Email Form" className="af-class-utility-page_form w-password-page" data-wf-page-id="6671e61719d3443cc0ddd675" data-wf-element-id="60d3fa3a5a19c1169cd58c4100000000000c"><img width={106} src="https://d3e54v103j8qbb.cloudfront.net/static/utility-lock.ae54711958.svg" alt className="af-class-utility-page_image" />
                  <h3>Protected Page</h3><input className="af-class-form_input w-password-page w-input" autofocus="true" maxLength={256} name="pass" data-name="field" placeholder="Enter your password" type="password" id="pass" /><input type="submit" data-wait="Please wait..." className="af-class-button w-password-page w-button" defaultValue="Submit" />
                  <div className="af-class-form_message-error w-password-page w-form-fail">
                    <div>Incorrect password. Please try again.</div>
                  </div>
                  <div style={{display: 'none'}} className="w-password-page w-embed w-script"><input type="hidden" name="path" defaultValue="<%WF_FORM_VALUE_PATH%>" /><input type="hidden" name="page" defaultValue="<%WF_FORM_VALUE_PAGE%>" /></div>
                  <div style={{display: 'none'}} className="w-password-page w-embed w-script">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default UnauthorizedView

/* eslint-enable */