import { useState } from "react";
import BasicDatePicker from '../shared/DatePicker';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function AddCalendarEntry(props){
    const [fields, setFields] = useState({ date: moment().format('YYYY-MM-DD'), content: 'Announce the new Air Pods launch date on social media.', platform: 'Social Media', type: 'post'})
  
    const handleSubmit = () =>{
      const newId = Math.floor(Math.random() * 900) + 101;
      const data = {
          ...fields, 
          id: newId, 
          media_url: null, 
          media_type: null,  
          details: {
            hashtag: "#AirPods2.0 #NewRelease #WirelessAudio",
            image: "link to teaser image",
            text: "Get ready for the future of listening! 🎧 Air Pods 2.0 are here. Learn more about the new features and pre-order yours today."
          }
        };
      props.handleCreateContent(data)
    }
    
    const handleChangeDate = (e) =>{
      const newDate = moment(e).format('YYYY-MM-DD')
      setFields({...fields, date: newDate})
    }
  
    return (
      <Dialog open maxWidth='md' fullWidth>
        <DialogTitle fontSize="28px" fontWeight={"300"} textAlign={'center'}>
            Add Content
          <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} style={{ alignItems: 'center'}}>
              <Grid item xs={12} sm={6}>
                <div className='form-group'>
                  <label className='label'>Date</label>
                  <BasicDatePicker onChangeDate={handleChangeDate} value={fields.date}/>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className='form-group'>
                  <label className='label'>Platform</label>
                    <Select 
                      id='platform'
                      name='platform'
                      value={fields.platform}
                      style={{ width: '100%', borderRadius: '30px', height: '50px', border: '5px solid #ddd', fontSize: '14px'}}
                      onChange={(e) => setFields({ ...fields, platform: e.target.value })}
                    >
                    <MenuItem value={'Blog'}>Blog</MenuItem>
                    <MenuItem value={'Social Media'}>Social Media</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className='form-group'>
                  <label className='label'>Type</label>
                  <Select 
                      id='type'
                      name='type'
                      value={fields.type}
                      style={{ width: '100%', borderRadius: '30px', height: '50px', border: '5px solid #ddd',  fontSize: '14px'}}
                      onChange={(e) => setFields({ ...fields, type: e.target.value })}
                    >
                    <MenuItem value={'post'}>Post</MenuItem>
                    <MenuItem value={'Video'}>Video</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className='form-group'>
                  <label className='label'>Content</label>
                    <textarea
                        id='content'
                        name='content'
                        type="text"
                        value={fields.content}
                        onChange={(e) => setFields({ ...fields, content: e.target.value })}
                        required
                        className="form-control"
                    />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: '15px 30px 20px'}}>
          <Button
              style={{ width: "50%" }}
              variant="outlined"
              color="error"
              size="large"
              onClick={props.onClose}
          >
          Close
          </Button>
          <Button
              style={{ width: "50%" }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleSubmit}
          >
              Add Content
          </Button>
          </DialogActions>
      </Dialog>
    )
  }

  export default AddCalendarEntry;