import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export default function BasicDatePicker(props) {
    const [startDate, setStartDate] = useState(props.value?? new Date());
    const  handleChange = (date) =>{
        props.onChangeDate(date)
        setStartDate(date)
    }

    return (
        <div className="datepicker">
            <DatePicker selected={startDate} onChange={(date) => handleChange(date)} className="form-control"  style={{ width: '100%'}} />
            <span className="icon"><CalendarTodayIcon style={{ fontSize: '18px'}}/></span>
        </div>
      
    );
}