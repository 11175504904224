/* eslint-disable */

import React, { useState } from 'react';
import { Button } from '@mui/material';
import VideoPlayer from './VideoPlayer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Carousel from 'react-material-ui-carousel';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function TiktokPreview({ content, pauseVideo }) {
    return (
        <div style={{ flex: "1" }}>
            <div style={{ height: "50px", textAlign: "center"}}>
                <img src='../images/tiktok_logo.png' alt='Facebook' style={{ height: "50px", transform: "scale(0.7)", transformOrigin: "center center",  marginTop: '-8px'}} />
            </div>
            <div className='content-builder-img' style={{ margin: "0px auto", position: "relative", width: "225px" }}>
                <VideoPlayer src={content.media_url_shorts} topics={[]} hideProgressBar={true} pauseVideo={pauseVideo} />
                <ul style={{ listStyle: 'none', display: 'flex', flexDirection: "column", gap: "10px", paddingLeft: '0', alignItems: 'center', position: "absolute", right: "10px", bottom: "20px", zIndex: "1"}}>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <img src='../images/tt_like_icon.png' style={{ width: "24px"}} />
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>1.3M</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <img src='../images/tt_comment_icon.png' style={{ width: "24px"}} /> 
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>10.7M</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <img src='../images/tt_share_icon.png' style={{ width: "24px"}} /> 
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>30.9K</span>
                    </li>
                </ul>
                <div style={{ padding: '15px 40px 0px 15px', display: "flex", flexDirection: "column", position: "absolute", right: "0", bottom: "15px", zIndex: "1"}}>
                    <span style={{ fontSize: '16px', fontWeight: '600', color: "#fff"}}>@nike</span>
                    <span style={{ fontSize: '12px', lineHeight: "16px", color: "#fff"}}>{content.content}</span>
                </div>
            </div>
        </div>
    )
}

function YoutubeShortsPreview({ content, pauseVideo }) {
    console.log('pauseVideo-YT', pauseVideo)
    return (
        <div style={{ flex: "1" }}>
            <div style={{ height: "50px", textAlign: "center"}}>
                <img src='../images/youtube_logo.png' alt='Facebook' style={{ height: "50px", transform: "scale(0.46)", transformOrigin: "center center", marginTop: '-8px'}} />
            </div>
            <div className='content-builder-img' style={{ margin: "0px auto", position: "relative", width: "225px" }}>
                <VideoPlayer src={content.media_url_shorts} topics={[]} hideProgressBar={true} pauseVideo={pauseVideo} />
                <ul style={{ listStyle: 'none', display: 'flex', flexDirection: "column", gap: "10px", paddingLeft: '0', alignItems: 'center', position: "absolute", right: "10px", bottom: "20px", zIndex: "1"}}>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <ThumbUpIcon  style={{ fontSize: '22px', color: '#fff'}} />
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>1.3M</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <ThumbDownIcon style={{ fontSize: '22px', color: '#fff'}} />
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>Dislike</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <CommentIcon style={{ fontSize: '22px', color: '#fff'}}/>
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>30.9K</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <ReplyIcon  style={{ fontSize: '22px', color: "#fff", transform: 'scaleX(-1)'}} />
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>Share</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <MoreVertIcon style={{ fontSize: '22px', color: '#fff'}}/>
                    </li>
                </ul>
                <div style={{ padding: '15px 40px 0px 15px', display: "flex", flexDirection: "column", position: "absolute", right: "0", bottom: "15px", zIndex: "1"}}>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: "#fff", marginBottom: '10px'}}><span style={{ width: '22px', height: '22px', borderRadius: '50%', background:'#9c27b0', display: 'inline-block', textAlign:'center',  color:'#fff', marginRight: '10px', lineHeight: '18px'}}>
                            <img src='../images/NikeLogo.jpg' alt='logo' style={{  borderRadius: '50%'}}/>
                        </span>@nike <span style={{ marginLeft: '10px', background: '#fff', border: '1px solid #fff', fontSize:'12px', padding: '6px', fontWeight: '400', borderRadius: '20px', color:'#000' }}>Subscribe</span></span>
                    <span style={{ fontSize: '12px', lineHeight: "16px", color: "#fff"}}>{content.content}</span>
                </div>
            </div>
        </div>
    )
}

function InstaReelPreview({ content, pauseVideo }) {
    console.log('pauseVideo-Insta', pauseVideo)
    return (
        <div style={{ flex: "1" }}>
            <div style={{ height: "50px", textAlign: "center" }}>
                <img src='../images/Instagram_logo.png' alt='Facebook' style={{ height: "50px", transform: "scale(0.8)", transformOrigin: "center center", marginTop: '-8px'}} />
            </div>
            <div className='content-builder-img' style={{ margin: "0px auto", position: "relative", width: "225px" }}>
                <VideoPlayer src={content.media_url_shorts} topics={[]} hideProgressBar={true} pauseVideo={pauseVideo} />
                <ul style={{ listStyle: 'none', display: 'flex', flexDirection: "column", gap: "10px", paddingLeft: '0', alignItems: 'center', position: "absolute", right: "10px", bottom: "20px", zIndex: "1"}}>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <FavoriteBorderIcon  style={{ fontSize: '22px', color: '#fff'}} />
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>1.3M</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <MapsUgcOutlinedIcon style={{ fontSize: '22px', color: '#fff'}} />
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>10.7M</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <SendOutlinedIcon style={{ fontSize: '22px', color: '#fff'}}/>
                        <span style={{ fontSize: '12px', color: "#fff", fontWeight: "600"}}>30.9K</span>
                    </li>
                    <li style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <MoreVertIcon style={{ fontSize: '22px', color: '#fff'}}/>
                    </li>
                </ul>
                <div style={{ padding: '15px 40px 0px 15px', display: "flex", flexDirection: "column", position: "absolute", right: "0", bottom: "15px", zIndex: "1"}}>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: "#fff", marginBottom: '10px'}}><span style={{ width: '32px', height: '32px', borderRadius: '50%', background:'#9c27b0', display: 'inline-block', textAlign:'center',  color:'#fff', marginRight: '10px', lineHeight: '30px'}}>
                            <img src='../images/NikeLogo.jpg' alt='logo' style={{  borderRadius: '50%'}}/>
                        </span>@nike<span style={{ marginLeft: '10px', border: '1px solid #fff', fontSize:'14px', padding: '6px', fontWeight: 'bold', borderRadius: '5px'}}>Follow</span>
                    </span>
                    <span style={{ fontSize: '12px', lineHeight: "16px", color: "#fff"}}>{content.content}</span>
                </div>
            </div>
        </div>
    )
}

const EntryReelsPreview = (props) => {
    const [pauseVideo, setPauseVideo] = useState(undefined)
    const pauseHiddenVideo = (now, prev) => {
        setPauseVideo(prev)
    }
    
    return (
        <Carousel animation="slide" autoPlay={false} indicators={false} navButtonsAlwaysVisible={true} height={500}   
            NextIcon={<ArrowForwardIosIcon/>}
            PrevIcon={<ArrowBackIosNewIcon/>}
            NavButton={({onClick, next, prev}) => {
                return (
                    <Button onClick={onClick} style={{ color: '#000', background: 'transparent', lineHeight: '15px'}}>
                        {next && <div><ArrowForwardIosIcon /></div>}
                        {prev && <div><ArrowBackIosNewIcon /></div>}
                    </Button>
                )
            }}
            onChange={(now, prev) => pauseHiddenVideo(now, prev)}
        >
            <TiktokPreview content={props.content} pauseVideo={pauseVideo === 0 ?? true} />
            <InstaReelPreview content={props.content} pauseVideo={pauseVideo === 1 ?? true} />
            <YoutubeShortsPreview content={props.content} pauseVideo={pauseVideo === 2 ?? true} />
        </Carousel>
    )
}

export default EntryReelsPreview;

/* eslint-enable */