/* eslint-disable */

import React, { useState } from 'react';
import { Button, Card, CardContent, CardHeader, Divider, Grid, MenuItem, Select, Skeleton, Stack } from '@mui/material';
import TimeLine from './TimeLine';
import AjaxLoader from './AjaxLoader';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RefreshIcon from '@mui/icons-material/Refresh';
import EntryVideoPostPreview from './EntryVideoPostPreview';
import EntryReelsPreview from './EntryReelsPreview';
import EntryPostPreview from './EntryPostPreview';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';


function NewsletterPreview({ content }) {
    return (
        <Card>
            <CardHeader style={{paddingBottom: '0'}} title={<div style={{ display: 'flex', width: '100%'}}> <span style={{ width: '32px', height: '32px', borderRadius: '50%', background:'#9c27b0', display: 'inline-block', textAlign:'center',  color:'#fff', marginRight: '10px', lineHeight: '32px', fontSize: '20px'}}>{content.type.charAt(0)}</span><span style={{ display: 'flex', flexDirection: 'column'}}><span style={{ fontSize: '16px', fontWeight: 'bold'}}>{content.type}</span> <span style={{ fontSize: '12px'}}>Sponsored</span></span><span style={{ marginLeft: 'auto'}}><MoreHorizIcon style={{ fontSize: '18px'}}/></span></div>} />
            <CardContent>
                <div className='content-builder-img' style={{ marginBottom: "0px" }}>
                    <p><span style={{ fontWeight: 'bold'}}>Subject:</span> <br />{content.details.subject}</p> 
                    <p><span style={{ fontWeight: 'bold'}}>Body:</span> <br />{content.details.body}</p>
                </div>
            </CardContent>
        </Card>
    )
}

const renderContentDetails = (content, refreshDayEntry, onPlatformChange, platform) => {
    const type = content.media_type ? content.media_type : content.type;
    switch (type.toLowerCase()) {
        case 'video':
            return (
                <>
                     <div style={{ textAlign: 'right'}}>
                        <Select value={platform} onChange={(e) =>onPlatformChange(e)} className='select' style={{border: '1px solid #ccc', marginBottom: '20px', height: '32px', borderRadius: '30px', minWidth: '150px', textAlign: 'center'}}>
                            <MenuItem value='post'>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                                    <span style={{ height: "18px", marginRight: "8px"}}><VideoLibraryIcon style={{ fontSize: '18px'}}/></span>
                                    <span>Posts</span>
                                </Stack>
                            </MenuItem>
                            <MenuItem value='reels'><span style={{ marginRight: '10px'}}><img style={{width: '16px'}} src='../images/instagram-reels-icon.png' /></span>Shorts/Reels</MenuItem>
                        </Select>
                    </div>
                    <div style={{ width: "400px", padding: "0 20px", margin: "0 auto"}}>
                    {platform === 'post' && 
                        <EntryVideoPostPreview content={content} />
                    }
                    {platform === 'reels' && 
                        <EntryReelsPreview content={content} />
                    }
                </div>
                </>
            
            );
        case 'newsletter':
            return (
                <NewsletterPreview content={content} />
            );
        default:
            return (
                <div>
                    <Stack direction={"column-reverse"} alignItems={"end"} style={{ marginBottom: "20px"}}>
                        <Button variant="outlined" size='small' startIcon={<RefreshIcon />} onClick={() => refreshDayEntry(content.id)}>
                            Refresh
                        </Button>
                    </Stack>
                   <EntryPostPreview content={content}/>
                </div>
            );
    }
};


const ContentBuilder = (props) => {
    const data = props.entryDetails && (props.entryDetails.entry ?? undefined);
    const [platform, setPlatform] = useState('post');
    const onPlatformChange = (e) => {
        setPlatform(e.target.value)
    }

  return (
    <div className='content_box' style={{ maxWidth: '100%', padding: '0px 20px'}}>
        {props.source === 'PLANNER' ? 
        <main>
            <h2 style={{ marginTop: "15px", fontWeight: "300", textAlign: "center" }}>Content Calendar</h2>
            <Divider />
            <div style={{ maxWidth: "100%", margin: "0 auto"}}>
            <Grid container spacing={2} style={{ alignItems: 'center'}}>
                <Grid item xs={12}>
                    {(props.mockPlayBookDetails || props.playbookDetails) && <><TimeLine playbookDetails={props.mockPlayBookDetails ?? props.playbookDetails} openCreateContent={props.openCreateContent} handleDeleteCalendarEntry={props.handleDeleteCalendarEntry}/>
                    <div style={{ marginBottom: "15px", textAlign: "center" }}><Button
                        variant="contained"
                        color="secondary"
                        size="large"
                    >
                    Approve and Submit
                    </Button></div></>}
                </Grid>
            </Grid>
            </div>
        </main>
        : <>
            {props.loading ? <div style={{ padding: '40px', width: '400px', margin: '0px auto'}}>
                <Card style={{ padding: '30px', width: '400px'}}>
                <Skeleton variant="text" height={30} width={300}/>
                <Skeleton variant="rectangular" animation={false} width={'100%'} height={360} />
                <Skeleton variant="text" animation={false} width={'100%'} height={30}/>
                </Card>
            </div>
            :
            (data && <main>
                <h2 style={{ marginTop: "15px", fontWeight: "300", textAlign: "center" }}>{props.title}</h2>
                <Divider />
                <div style={{ maxWidth: "100%", width: "100%", margin: "0 auto", padding: '30px 0px'}}>
                    {renderContentDetails(data, props.refreshDayEntry, onPlatformChange, platform)}
                </div>
            </main>)}
          </>
       
        }
        {props.loader && <AjaxLoader open={props.loader} />}
    </div>
  );
};

export default ContentBuilder;

/* eslint-enable */