import React from 'react';
import './MarketingPlanModal.css';
import Button from './Button';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const MarketingPlanModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <>
    {/*<div className="mp-modal-overlay">
      <div className="mp-modal-content">
        {children}
      </div>
      <Button className="mp-modal-close" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </Button>
    </div>*/}
    <Dialog maxWidth='md' fullWidth open onClose={onClose}>
     
      <DialogTitle>
      <h3 style={{ margin: 0}}>Marketing Plan</h3>
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
      </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="mp-modal-content">
          {children}
        </div>
      </DialogContent>
    </Dialog>
    </>
  );
};

export default MarketingPlanModal;