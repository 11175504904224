import {
  Avatar,
  Button,
  Chip,
  Fab,
  MenuItem,
  MenuList,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  UserButton,
} from "@clerk/clerk-react";
import useAxiosInstance from "../axiosInstance";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import NewPlaybookDialog from "../components/NewPlaybookDialog";

const projectsMockList = [
  {
    id: 1,
    name: "Nike Trends 2024"
  },
  {
    id: 2,
    name: "Step Into Nike"
  },
  {
    id: 3,
    name: "Elevate with Nike"
  },
  {
    id: 4,
    name: "Unleash Your Potential: The Power of Nike Footwear"
  },
  {
    id: 5,
    name: "Nike: Power in Every Step"
  },
  {
    id: 6,
    name: "Fuel Your Passion: Nike Shoes for Every Athlete"
  },
  {
    id: 7,
    name: "Nike: Beyond Limits"
  },
  {
    id: 8,
    name: "Run with Nike"
  },
  {
    id: 9,
    name: "Nike: The Future of Footwear"
  },
  {
    id: 10,
    name: "Feel the Nike Difference"
  },
]

const Sidebar = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [isProjectListOpen, setIsProjectListOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const navigation = useNavigate();
  // Use the custom axios instance
  const axiosInstance = useAxiosInstance();

  const toggleProjectList = () => {
    setIsProjectListOpen(!isProjectListOpen);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        //const response = await axiosInstance.get(`/playbooks`);
        setProjects(projectsMockList);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  // const onSelectProject = (project) => {
  //   navigation('/playbook/' + project.id);
  // }

  const onSelectThread = (thread) => {
    navigation(`/playbook/${thread.name}?activeTab=1`);
  };

  const onToggleSidebar = () => {
    setToggleSidebar(!toggleSidebar);
  };

  return (
    <>
      <div
        className={
          toggleSidebar ? "sidebar-wrapper" : "sidebar-wrapper collapsed"
        }
      >
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            onToggleSidebar();
          }}
          className="visible-sm toggleBtn"
        >
          {toggleSidebar ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <div className="sidebar h_full" style={{ overflow: 'scroll'}}>
          <div>
            <div className="logo">
              <Link to="/" className="af-class-navbar2_logo-link w-nav-brand">
                <img
                  src="/images/byteroll-logo-white.png"
                  loading="lazy"
                  alt=""
                  className="af-class-navbar2_logo af-class-desktop"
                />
              </Link>
            </div>
            <div className="new-playbook-button">
              <Button
                onClick={() => setShowProjectDialog(true)}
                className="sb-playbook-button"
              >
                <span>New Playbook</span>{" "}
                <AutoStoriesOutlinedIcon
                  style={{ marginLeft: "10px" }}
                  fontSize="small"
                />
              </Button>
            </div>
            <div className="sidebar-menu">
              <MenuList>
                <MenuItem>
                  <Link
                    id="w-node-_80f0ae4c-c804-42b7-43c9-44e38a9444fa-8a9444f8"
                    to="/"
                    className="af-class-sidebar-menu-item w-inline-block"
                  >
                    <HomeOutlinedIcon />
                    <span>Home</span>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <div
                    className="parent"
                    onClick={() => setIsProjectListOpen(!isProjectListOpen)}
                  >
                    <LocalLibraryOutlinedIcon />
                    <span>Projects</span>
                  </div>
                </MenuItem>
                {isProjectListOpen && (
                  <MenuItem className="no_bg">
                    <ul className="thread-list" style={{ borderRadius: 0}}>
                      {projects.map((thread, index) => (
                        <li key={index} onClick={() => onSelectThread(thread)} style={{ display: '-webkit-box', WebkitBoxOrient:'vertical', overflow: 'hidden', WebkitLineClamp: '1'}}>
                          {thread.name}
                        </li>
                      ))}
                    </ul>
                  </MenuItem>
                )}
              </MenuList>
            </div>
          </div>
          <div className="sidebar-footer">
            <Button className="profile-btn" variant="extended">
              <UserButton showName={true} />
            </Button>
          </div>
        </div>
      </div>
      {showProjectDialog && (
        <NewPlaybookDialog
          open={showProjectDialog}
          onClose={() => setShowProjectDialog(false)}
          checkedContents={[]}
          setShowCollections={false}
        />
      )}
    </>
  );
};

export default Sidebar;
