/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { createScope, map, transformProxies } from './helpers';
import Navigation from '../components/Navigation';
import GlobalStyles from '../components/GlobalStyles';
import Button from '../components/Button';
import TextField from '../components/TextField';
import Form from '../components/Form';
import Navbar from '../components/Navbar';

const scripts = [
  { src: "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6671e61719d3443cc0ddd63c", async: false },
  { src: "/js/webflow.js", async: false },
];

const loadScript = (src, async = false) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = async;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

const AccountSettingsView = () => {

  useEffect(() => {
    const loadScripts = async () => {
      try {
        for (const script of scripts) {
          await loadScript(script.src, script.async);
        }
        console.log('All scripts loaded');
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };

    loadScripts();
  }, []);

    return (
      <span>
        <GlobalStyles />
        <span className="af-view">
            <div className="af-class-page-wrapper">
              <main className="af-class-main-wrapper">
                <Navbar />
                <header className="af-class-content-wrap">
                    <div className="af-class-container-medium">
                      <div className="af-class-padding-section-small">
                        <div className="w-layout-grid af-class-content-grid">
                          <div id="w-node-_1077fcef-8ece-dd8b-7ff6-718f772a75d0-c0ddd677" className="af-class-sidebar-content">
                            <Navigation />
                          </div>
                          <div className="af-class-main-content">
                            <div className="af-class-inner-content">
                              <div className="af-class-inner-content-group">
                                <div className="af-class-page-title-group">
                                  <h1 className="af-class-heading-style-h2">😎 Account settings</h1>
                                  <p className="af-class-text-color-grey">The community loves a good profile</p>
                                </div>
                                <div className="af-class-divider" />
                              </div>
                              <div className="af-class-inner-content-group af-class-gap-32px">
                                <div className="w-layout-grid af-class-_2-col-grid af-class-settings">
                                  <div className="af-class-content-wrap">
                                    <h2 className="af-class-heading-style-h5">Profile Information</h2>
                                    <p className="af-class-text-color-grey">Update your account's profile information and email address.</p>
                                  </div>
                                  <div className="af-class-form_component w-form">
                                    <Form id="wf-form-Form" name="wf-form-Form">
                                      <TextField label="Brand name" name="Brand-name" placeholder="Nike" />
                                      <TextField label="Email address" name="Email-address" placeholder="nike@gmail.com" />
                                      <Button type="submit">Save changes</Button>
                                    </Form>
                                    <div className="af-class-form_message-success w-form-done">
                                      <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="af-class-form_message-error w-form-fail">
                                      <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-divider" />
                              </div>
                              <div className="af-class-inner-content-group af-class-gap-32px">
                                <div className="w-layout-grid af-class-_2-col-grid af-class-settings">
                                  <div className="af-class-content-wrap">
                                    <h2 className="af-class-heading-style-h5">Update Password</h2>
                                    <p className="af-class-text-color-grey">Ensure your account is using a long, random password to stay secure.</p>
                                  </div>
                                  <div className="af-class-form_component w-form">
                                    <Form id="wf-form-Form" name="wf-form-Form">
                                      <TextField label="Current Password" name="Full-name-2" type="password" />
                                      <TextField label="New Password" name="New-Password" type="password" />
                                      <TextField label="Confirm Password" name="Confirm-Password" type="password" />
                                      <Button type="submit">Save changes</Button>
                                    </Form>
                                    <div className="af-class-form_message-success w-form-done">
                                      <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="af-class-form_message-error w-form-fail">
                                      <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="af-class-divider" />
                              </div>
                              <div className="af-class-inner-content-group af-class-gap-32px">
                                <div className="w-layout-grid af-class-_2-col-grid af-class-settings">
                                  <div className="af-class-content-wrap">
                                    <h2 className="af-class-heading-style-h5">Delete Account</h2>
                                    <p className="af-class-text-color-grey">Permanently delete your account.</p>
                                  </div>
                                  <div className="af-class-form_component w-form">
                                    <Form id="wf-form-Form" name="wf-form-Form">
                                      <p className="af-class-text-color-grey">Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.</p>
                                      <Button type="submit" className="af-class-margin-top af-class-margin-medium af-class-background-danger" style={{ backgroundColor: 'red'}}>Delete my account</Button>
                                    </Form>
                                    <div className="af-class-form_message-success w-form-done">
                                      <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="af-class-form_message-error w-form-fail">
                                      <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </header>
              </main>
            </div>
        </span>
      </span>
    );
};

export default AccountSettingsView;

/* eslint-enable */