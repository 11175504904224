import React, { useState } from "react";
import useAxiosInstance from "../axiosInstance";
import "./FileUploadModal.css";
import { useAuth } from "@clerk/clerk-react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import AjaxLoader from "./AjaxLoader";

const FileUploadModal = ({ isOpen, onClose, onUploadSuccess, modalType }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const axiosInstance = useAxiosInstance();

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const type = file.type.split("/")[0];
    setFileType(type);
  };

  // Handle upload
  const handleUpload = async () => {
    const formData = new FormData();

    if (modalType === "URL") {
      formData.append("url", url);
    } else {
      formData.append("file", selectedFile);
      formData.append("fileType", fileType);
    }

    setLoading(true);

    try {

      const response = await axiosInstance.post(
        `/upload_content`, // Use POST instead of GET
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true, // If you need to send cookies
        }
      );

      if (response.data.success) {
        onUploadSuccess(
          response.data.name,
          response.data.type,
          response.data.url,
          response.data.thumbnail_url,
          response.data.metadata,
          response.data.voiceover_tags,
          response.data.topics
        );
      }
      setUrl("");
      onClose();
    } catch (error) {
      setUrl("");
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        {modalType === "URL" ? "Enter URL" : "Upload Content"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div style={{ textAlign: "center" }}>
          {modalType === "URL" ? (
            <input
              id="url"
              className="file-upload"
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              disabled={loading}
            />
          ) : (
            <input
              id="fileUpload"
              className="file-upload"
              type="file"
              onChange={handleFileChange}
              disabled={loading}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={handleUpload}
          disabled={loading}
          color="secondary"
          size="large"
          style={{ width: "200px" }}
        >
          Upload
        </Button>
      </DialogActions>
      <AjaxLoader open={loading} />
    </Dialog>
  );
};

export default FileUploadModal;
