import React, { useState } from 'react';
import './ContentCard.css';
import { Card, CardContent, Checkbox, Chip, IconButton, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { SOURCE } from '../views/IndexView';

const ContentCard = ({ content, onClick, handleAddToCollection, handleRemoveCollection, handleSelectedCollection, contentSrc }) => {
    const [isChecked, setIsChecked] = useState(content.isChecked ?? false)

    const handleAddCollection = (e) => {
        
        handleSelectedCollection({...content, isChecked: !isChecked})
        setIsChecked(!isChecked)
    };

    const renderContentDetails = (content) => {
        switch (content.type) {
            case 'image':
                return (
                        <div>
                            <img src={content.url} alt={content.name} className="content-thumbnail" />
                            {contentSrc !== SOURCE.SIDEBAR && contentSrc !== SOURCE.DIALOG && content.voiceover_tags && content.voiceover_tags.length > 0 && (
                                <div className="chips-container">
                                    {content.voiceover_tags.map((tag, index) => (
                                        <Chip key={index} label={tag} size="small" style={{ margin: "5px 0 0 5px"}} />
                                    ))}
                                </div>
                            )}
                        </div>
                        );
            case 'video':
                return (
                    <div>
                        <video controls className="content-thumbnail">
                            <source src={content.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        {contentSrc !== SOURCE.SIDEBAR && contentSrc !== SOURCE.DIALOG && content.voiceover_tags && content.voiceover_tags.length > 0 && (
                            <div className="chips-container">
                                {content.voiceover_tags.map((tag, index) => (
                                    <Chip key={index} label={tag} size="small" style={{ margin: "5px 0 0 5px"}} />
                                ))}
                            </div>
                        )}
                    </div>
                );
            case 'pdf':
                return (
                    <>
                        <div className="content-thumbnail">
                            <embed src={content.url} type="application/pdf" width="100%" height="100px" />
                        </div>
                        {contentSrc !== SOURCE.SIDEBAR && contentSrc !== SOURCE.DIALOG && content.voiceover_tags && content.voiceover_tags.length > 0 && (
                            <div className="chips-container">
                                {content.voiceover_tags.map((tag, index) => (
                                    <Chip key={index} label={tag} size="small" style={{ margin: "5px 0 0 5px"}} />
                                ))}
                            </div>
                        )}
                    </>
                );
            case 'link':
                return (
                    <>
                        <img src={content.thumbnail_url} alt={content.name} className="content-thumbnail" />
                        {contentSrc !== SOURCE.SIDEBAR && contentSrc !== SOURCE.DIALOG && content.voiceover_tags && content.voiceover_tags.length > 0 && (
                            <div className="chips-container">
                                {content.voiceover_tags.map((tag, index) => (
                                    <Chip key={index} label={tag} size="small" style={{ margin: "5px 0 0 5px"}} />
                                ))}
                            </div>
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Card className='home-thumbnails' sx={{ maxWidth: 345 }} style={{ position: "relative"}}>
            {contentSrc !== SOURCE.SIDEBAR && <span className='btn-add-to-collection'>
                <Checkbox checked={isChecked} onChange={handleAddCollection} />
            </span>}
            <div onClick={() => onClick(content)}>{renderContentDetails(content)}</div>
            {contentSrc !== SOURCE.SIDEBAR && contentSrc !== SOURCE.DIALOG && <><CardContent className='thumbnail-footer' onClick={() => onClick(content)}>
                <Typography gutterBottom variant="h5" component="div" margin={0}>
                    {content.name}
                </Typography>
                
            </CardContent>
            <Stack direction="row" justifyContent="end">
            <div className='cardBottom'>
                {content.similarity &&<span style={{ color: "#146ef5", fontSize: "12px", marginTop: "4px", textAlign: "right"}}>{(content.similarity.toFixed(2))+"%"}</span>}
            </div>
            </Stack></>
            }
        </Card>
    );
};

export default ContentCard;
