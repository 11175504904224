import React, { useEffect, useState } from 'react';
import { Button, capitalize, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import axios from "axios";
import AjaxLoader from './AjaxLoader';

const EntryDetails = ({ title, data }) => {
    return (
      <div style={{ margin: "0 0 10px 0", fontSize: "14px"}}>
        <div style={{ fontWeight: "600", textTransform: "capitalize"}}>{title}</div>
        {typeof data === 'object' && Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <span style={{ textTransform: "capitalize"}}>{key}</span> : {value}
          </div>
        ))}
        {typeof data === 'string' && 
          <div>{data}</div>
        }
      </div>
    );
};

function DeleteDialog (props){
    return (
        <Dialog open maxWidth='sm' fullWidth onClose={props.handleClose}>
            <DialogTitle fontSize="28px" fontWeight={"300"} textAlign={"center"}>
                Are you sure?
            </DialogTitle>
            <DialogContent style={{ }}>
                <p style={{ textAlign: 'center', margin: 0}}>Do you really want to delete this item?</p>
            </DialogContent>
            <DialogActions style={{ padding: '0px 15px 20px'}}>
                <Button variant='outlined' style={{ width: "50%" }} onClick={props.handleClose}>Cancel</Button>
                <Button variant='contained'  style={{ width: "50%" }} color='error' onClick={props.handleDeleteItem}>Delete</Button>
            </DialogActions>
        </Dialog>
    )
}

function TimeLine(props) {
    const [timeLine, setTimeLine] = useState(undefined)
    const [activeClass, setActiveClass] = useState(undefined)
    const [showDialog, setShowDialog] = useState(undefined)
    const [selectedId, setSelectedId] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(props.playbookDetails) setTimeLine(props.playbookDetails.content_calendar[0].entries)
    }, [props.playbookDetails])

    console.log('timeLine',timeLine)

    const addTimelineItem = () => {
        props.openCreateContent()
    }

    const deleteCalendarEntry = async() => {
        setLoading(true)
        try {
            //await axios.delete(`${process.env.REACT_APP_API_URL}/delete_calendar_entry/${selectedId}`)
            deleteTimelineItem(selectedId)
            setShowDialog(false)
            setLoading(false)
        }
        catch(err) {
          setLoading(false)
        }
    }

    const handleOpenDltDialog = (id) =>{
        setSelectedId(id)
        setShowDialog(true)
    }

    const handleCloseDltDialog = () =>{
        setShowDialog(false)
    }

    const deleteTimelineItem = (selectedId) => {
        let newTimeLine = [...timeLine];
        newTimeLine = newTimeLine.filter(item => item.id !== selectedId);
        setTimeLine(newTimeLine);
        setShowDialog(false);
        props.handleDeleteCalendarEntry(selectedId)
    }

    return (
        <>
        <Timeline style={{ padding: '15px'}}>
            { timeLine && timeLine.map((item, index) => {
                return (
                    <TimelineItem key={item.date + index}>
                        <TimelineSeparator>
                            <TimelineDot onClick={() => handleOpenDltDialog(item.id)} style={{ cursor: 'pointer'}}>
                                <Tooltip title="Delete Content"><RemoveRoundedIcon fontSize='small' /></Tooltip>
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent className={`timeline-text ${activeClass === item.id ? `active` : ``}` } style={{ cursor: "pointer" }} onClick={() => {if(props.showCreateForm) { setActiveClass(item.id); props.showCreateForm(item.date, item)}}}>
                            <div style={{ margin: "8px 0 5px 0", fontSize: "14px"}}>
                                <span style={{ fontWeight: "600"}}>{item.date}</span> : {item.content}
                            </div>
                            {Object.entries(item.details).map(([key, value], index) => {
                                return <EntryDetails key={index} title={key} data={value} />
                            })}
                        </TimelineContent>
                    </TimelineItem>
                )
            })}
            <TimelineItem>
                
                <TimelineSeparator>
                    <TimelineDot onClick={addTimelineItem} style={{ cursor: 'pointer'}}>
                        <Tooltip title="Add Content"><AddRoundedIcon fontSize='small' /></Tooltip>
                    </TimelineDot>
                </TimelineSeparator>
            </TimelineItem>
        </Timeline>
        {showDialog && <DeleteDialog handleDeleteItem={deleteCalendarEntry} handleClose={handleCloseDltDialog}/>}
        <AjaxLoader open={loading} />
    </>
    )
}

export default TimeLine; 