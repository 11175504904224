import { Drawer, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ContentCard from "../components/ContentCard";
import { SOURCE } from "../views/IndexView";

function SidebarDrawer(props) {
    return (
        <Drawer
          open={props.showCollections}
          anchor='right'
          onClose={() => props.setShowCollections(false)}
          className='mui-drawer-wrapper'
          style={{ zIndex: "1400"}}
        >
            <div role="presentation" className='selectedProjectItem' id='second-row'>
                <div className="item-row" style={{ display: 'flex', flexWrap: 'wrap', margin: '10px 0px 0px'}}>
                    {props.checkedContents?.map((item, index) => {
                        return (
                        <div key={item.id} className='item'>
                            <ContentCard key={index} content={item} onClick={() => props.handleCardClick(item)} contentSrc={SOURCE.SIDEBAR} />
                        </div>
                        )
                    })}
                </div>
            </div>
        </Drawer>
    )
}

export default SidebarDrawer;