import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

function AjaxLoader ({ open }) {
    return (
        <Backdrop open={open} style={{ zIndex: '9999'}}>
            <CircularProgress style={{ color: '#fff'}} />
        </Backdrop>
    )
}

export default AjaxLoader;